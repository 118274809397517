import axios from "axios";
import { apiInstanceFetch } from "../../util/api";
import { Toast } from "../../util/Toast";
import { BLOCK_UNBLOCK_SWITCH, BLOCK_UNBLOCK_SWITCH1, GET_ALL_HOSTS } from "./types";

export const getHosts =
    (start, limit, searchValue, sDate, eDate) => (dispatch) => {
        apiInstanceFetch
            .get(`host?start=${start}&limit=${limit}&search=${searchValue}`)
            .then((res) => {
                if (res.status) {
                    dispatch({
                        type: GET_ALL_HOSTS,
                        payload: {
                            host: res.user,
                            total: res.total,
                        },
                    });
                } else {
                    Toast("error", res.message);
                }
            })
            .catch((error) => Toast("error", error.message));
    };

export const handleBlockUnblockSwitch = (payload, userId) => (dispatch) => {
    axios
        .patch(`user/blockUnblock/${userId}`, payload)
        .then((res) => {
            if (res.data.status) {
                dispatch({
                    type: BLOCK_UNBLOCK_SWITCH,
                    payload: res.data.user,
                });
                Toast("success", "User block Succesfully");
            } else {
                Toast("error", res.data.message);
            }
        })
        .catch((error) => Toast("error", error.message));
};

export const handleBlockUnblockSwitch1 = (userId) => (dispatch) => {
    axios
        .patch(`user/blockUnblock/${userId}`)
        .then((res) => {
            if (res.data.status) {
                dispatch({
                    type: BLOCK_UNBLOCK_SWITCH1,
                    payload: res?.data?.user,
                });
                Toast("success", "User Unblock Succesfully");
            } else {
                Toast("error", res.data.message);
            }
        })
        .catch((error) => Toast("error", error.message));
};
