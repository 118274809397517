import axios from "axios";
import { Toast } from "../../util/Toast";
import { GET_DOWNLOAD_REPORT, GET_SETTING, UPDATE_SETTING } from "./types";
import { apiInstanceFetch } from "../../util/api";

export const getSetting = () => (dispatch) => {
  apiInstanceFetch
    .get("setting")
    .then((res) => {
      if (res.status) {
        dispatch({ type: GET_SETTING, payload: res.setting });
      } else {
        Toast("error", res.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};

export const updateSetting = (settingId, data) => (dispatch) => {
  axios
    .patch(`setting/${settingId}`, data)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: GET_SETTING, payload: res.data.setting });
        Toast("success", "Updated Successfully!");
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};

export const handleSwitch = (settingId, type) => (dispatch) => {
  axios
    .put(`setting/${settingId}?type=${type}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: UPDATE_SETTING, payload: res.data.setting });
        Toast("success", "Updated Successfully!");
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};

export const handleDownloadReport = (payload) => (dispatch) => {

  Toast("success", "Download Report Will be Soon!");

  axios
    .get(`/hostLiveHistory/getAllAgenciesHostLiveHistory?startDate=${payload?.startDate}&endDate=${payload?.endDate}`, {
      responseType: "blob",
    })
    .then((res) => {
      if (res.status === 200) {
        dispatch({ type: GET_DOWNLOAD_REPORT, payload: res.data });

        // ✅ Trigger file download
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Host_Payment_Report.xlsx");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(url);
      }
    })
    .catch((error) => {
      console.error("Download Error:", error);
    });
};

