import React, { useEffect, useState } from "react";

//dayjs
import dayjs from "dayjs";

//jquery
import $ from "jquery";

//redux
import { connect, useDispatch, useSelector } from "react-redux";

//action
import {
  getAgencyWiseHost,
  handleBlockUnblockSwitch,
  redeemEnableHost,
  getAgencyLiveHistory,
} from "../store/agency/action";

//routing
import { Link, useHistory } from "react-router-dom";

//MUI
import { Tooltip } from "@material-ui/core";

// import arraySort from "array-sort";

//image
import Male from "../assets/images/male.png";

//pagination
import Pagination from "./Pagination";

//Date Range Picker
import { DateRangePicker } from "react-date-range";
//Calendar Css
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

//MUI icon
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const convertMinutes = (totalMinutes) => {
  const hours = Math.floor(totalMinutes / 60); // Calculate full hours
  const minutes = totalMinutes % 60; // Remaining minutes

  if (hours > 0 && minutes > 0) {
    return `${hours} hr${hours > 1 ? "s" : ""} ${minutes} min${
      minutes > 1 ? "s" : ""
    }`;
  } else if (hours > 0) {
    return `${hours} hr${hours > 1 ? "s" : ""}`;
  } else {
    return `${minutes} min${minutes > 1 ? "s" : ""}`;
  }
};

const AgencyWiseLiveHistory = (props) => {
  const maxDate = new Date();
  const dispatch = useDispatch();
  const location = useLocation();

  // Date handling
  const startOfMonth = dayjs().startOf("month").format("YYYY-MM-DD");
  const endOfMonth = dayjs().endOf("month").format("YYYY-MM-DD");

  const [date, setDate] = useState([
    {
      startDate: dayjs().startOf("month").toDate(),
      endDate: dayjs().endOf("month").toDate(),
      key: "selection",
    },
  ]);
  const [sDate, setsDate] = useState(startOfMonth);
  const [eDate, seteDate] = useState(endOfMonth);
  const [data, setData] = useState([]);

  // Add pagination states
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [activePage, setActivePage] = useState(1);

  // Add pagination handlers
  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const handleRowsPerPage = (value) => {
    setActivePage(1);
    setRowsPerPage(value);
  };

  // Fetch data only when button is clicked
  const handleFetchData = () => {
    const dayStart = dayjs(date[0].startDate).format("YYYY-MM-DD");
    const dayEnd = dayjs(date[0].endDate).format("YYYY-MM-DD");

    // Update displayed dates
    setsDate(dayStart);
    seteDate(dayEnd);

    // Call API with selected dates
    const agencyId = location?.state?.id;
    if (agencyId) {
      dispatch(getAgencyLiveHistory(agencyId, dayStart, dayEnd));
    }

    // Close the date picker
    $("#datePicker").addClass("collapse");
  };

  // Date picker collapse handling
  const collapsedDatePicker = () => {
    $("#datePicker").toggleClass("collapse");
  };

  // Initial API call with current month's dates
  useEffect(() => {
    const agencyId = location?.state?.id;
    if (agencyId) {
      dispatch(
        getAgencyLiveHistory(
          agencyId,
          startOfMonth, // Initial start date
          endOfMonth // Initial end date
        )
      );
    }
  }, [dispatch, location?.state?.id]); // Remove sDate, eDate from dependencies

  // Date selection handler - only updates the date state
  const handleDateChange = (item) => {
    const { startDate, endDate } = item.selection;
    if (startDate && endDate) {
      setDate([item.selection]);
    }
  };

  // set default image

  $(document).ready(function () {
    $("img").bind("error", function () {
      // Set the default image
      $(this).attr("src", Male);
    });
  });

  useEffect(() => {
    // Get agencyId from location state
    const agencyId = location?.state?._id;
    console.log("agencyId", agencyId);
    // Call API when component mounts with current month's date range
    if (agencyId) {
      dispatch(getAgencyLiveHistory(agencyId, sDate, eDate));
    }
  }, [dispatch, location?.state?.id, sDate, eDate]);

  // Get data from redux store
  const { agencyLiveHistory, totalAgencyLiveHistory } = useSelector(
    (state) => state.agency
  );

  // Update your data state when redux store updates
  useEffect(() => {
    if (agencyLiveHistory) {
      setData(agencyLiveHistory);
      console.log("data", agencyLiveHistory);
    }
  }, [agencyLiveHistory]);

  // Add this helper function for name capitalization
  const capitalizeFirstLetter = (string) => {
    if (!string) return "-";
    return string
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  // Calculate pagination indexes
  const indexOfLastRow = activePage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = agencyLiveHistory
    ? agencyLiveHistory.slice(indexOfFirstRow, indexOfLastRow)
    : [];

  return (
    <>
      <div className="page-title">
        <div className="row">
          <div className="col-12 col-md-6 order-md-1 order-last">
            <h3
              className="mb-3 text-light"
              style={{ color: "#e4eeff", textTransform: "capitalize" }}
            >
              Host Live History
            </h3>
          </div>
          <div className="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              className="breadcrumb-header float-start float-lg-end"
            >
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/admin/dashboard" className="text-danger">
                    Dashboard
                  </Link>
                </li>
                <li
                  className="breadcrumb-item active"
                  aria-current="page"
                  onClick={() => window.history.back()}
                >
                  Agency
                </li>
                <li className="breadcrumb-item active " aria-current="page">
                  Host Live History
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="card" id="card">
            <div className="card-header pb-0">
              <div className="row my-3">
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-8 float-left">
                  <div className="d-flex justify-content-between">
                    <div className="text-left align-sm-left d-md-flex d-lg-flex justify-content-start">
                      <button
                        className="btn btn-info"
                        style={{ marginRight: 5 }}
                      >
                        All
                      </button>
                      <button
                        className="collapsed btn btn-info ml-5"
                        value="check"
                        data-toggle="collapse"
                        data-target="#datePicker"
                        onClick={collapsedDatePicker}
                      >
                        Analytics
                        <ExpandMoreIcon />
                      </button>
                      <p style={{ paddingLeft: 10 }} className="my-2">
                        {sDate !== "ALL" && sDate + " to " + eDate}
                      </p>
                    </div>

                    <div>
                      <Tooltip title="Back">
                        <button
                          type="button"
                          className="btn btn-sm btn-success  text-center p-auto ms-auto me-2"
                          style={{
                            backgroundColor: "#fc9494",
                            width: "85px",
                            outline: "none",
                          }}
                          onClick={() => window.history.back()}
                        >
                          Back
                        </button>
                      </Tooltip>
                    </div>
                  </div>
                </div>

                <div
                  id="datePicker"
                  className="collapse mt-5 pt-5"
                  aria-expanded="false"
                >
                  <div className="container table-responsive">
                    <div key={JSON.stringify(date)}>
                      <DateRangePicker
                        maxDate={maxDate}
                        onChange={handleDateChange}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        ranges={date}
                        direction="horizontal"
                      />
                    </div>
                    <button
                      className="btn btn-primary mt-3"
                      onClick={handleFetchData}
                    >
                      Fetch Data
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body card-overflow pt-0">
              <table className="table table-striped mt-5 text-center">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Image</th>
                    <th>Name</th>
                    <th>UniqueId</th>
                    <th>Audio Duration</th>
                    <th>Valid Days (Audio)</th>
                    <th>Video Duration</th>
                    <th>Valid Days (Video)</th>
                    <th>Total Earning</th>
                    <th>Diamonds (Wallet)</th>
                  </tr>
                </thead>
                <tbody>
                  {currentRows?.length > 0 ? (
                    currentRows.map((data, index) => {
                      return (
                        <tr key={index}>
                          <td>{indexOfFirstRow + index + 1}</td>
                          <td>
                            <img
                              height="50px"
                              width="50px"
                              alt="app"
                              src={data?.host?.image ? data?.host?.image : Male}
                              style={{
                                boxShadow: "0 5px 15px 0 rgb(105 103 103 / 0%)",
                                border: "2px solid #fff",
                                borderRadius: 10,
                                objectFit: "cover",
                                display: "block",
                              }}
                              className="mx-auto"
                            />
                          </td>
                          <td>
                            {data?.host?.name
                              ? capitalizeFirstLetter(
                                  data?.host?.name.length > 15
                                    ? data?.host?.name.substring(0, 15) + "..."
                                    : data?.host?.name
                                )
                              : "-"}
                          </td>
                          <td>
                            {data?.host?.uniqueId ? data?.host?.uniqueId : "-"}
                          </td>
                          <td className="text-danger">
                            {convertMinutes(data?.audioData?.totalLiveDuration)}
                          </td>
                          <td>{data?.audioData?.validCount}</td>
                          <td className="text-danger">
                            {convertMinutes(data?.videoData?.totalLiveDuration)}
                          </td>
                          <td>{data?.videoData?.validCount}</td>
                          <td className="text-danger">{data?.totalEarning}</td>
                          <td>{data?.host.diamond}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="8" align="center">
                        Nothing to show!!
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>

              <Pagination
                activePage={activePage}
                rowsPerPage={rowsPerPage}
                userTotal={agencyLiveHistory?.length || 0}
                handleRowsPerPage={handleRowsPerPage}
                handlePageChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { getAgencyLiveHistory })(AgencyWiseLiveHistory);
