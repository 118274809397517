import React, { useEffect, useState } from "react";

//redux
import { connect, useDispatch, useSelector } from "react-redux";

//action
import {
  getAgency,
  enableDisableAgency,
  redeemEnableAgency,
} from "../store/agency/action";

//routing
import $, { type } from "jquery";
import { Link } from "react-router-dom";
import Male from "../assets/images/male.png";
//MUI
import { Tooltip } from "@material-ui/core";

import AgencyDialogue from "../component/dialog/AgencyDialogue";

//sweet alert
import { alert, warning } from "../util/Alert";
import { OPEN_AGENCY_DIALOG } from "../store/agency/type";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Pagination from "./Pagination";
import SwitchBd from "../component/dialog/SwitchBd";
import VerifiedAgency from "../component/table/agency/VerifiedAgency";
import UnverifiedAgency from "../component/table/agency/UnverifiedAgency";

const AgencyDetails = (props) => {
  const {dialog ,type} = useSelector((state) => state.dialog);
  const dispatch = useDispatch();
  
  const [agencyType, setAgencyType] = useState("Verified")

  $(document).ready(function () {
    $("img").bind("error", function () {
      // Set the default image
      $(this).attr("src", Male);
    });
  });

  return (
    <>
      <div className="page-title">
        <div className="row">
          <div className="col-12 col-md-6 order-md-1 order-last">
            <h3 className="mb-3 text-white"> {agencyType} Agency</h3>
          </div>
          <div className="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              className="breadcrumb-header float-start float-lg-end"
            >
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/admin/dashboard" className="text-danger">
                    Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Agency
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-11">
          <div className="my-2">
            <button
              type="button"
              className={`btn btn-sm ${agencyType === "Verified" ? "btn-info" : "disabledBtn"
                }`}
              onClick={() => setAgencyType("Verified")}
            >
              <span className="">Verified</span>
            </button>
            <button
              type="button"
              className={`btn btn-sm ${agencyType === "Unverified" ? "btn-danger" : "disabledBtn"
                } ms-3`}
              onClick={() => setAgencyType("Unverified")}
            >
              <span className="">Unverfied</span>
            </button>
          </div>
        </div>
      </div>
      {agencyType === "Verified" ? <VerifiedAgency /> : <UnverifiedAgency />}
      <AgencyDialogue />

    </>
  );
};

export default connect(null, { getAgency, enableDisableAgency })(AgencyDetails);
