import { Link, Tooltip } from "@material-ui/core";
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { getBlockUser, handleBlockUnblockSwitch, handleBlockUnblockSwitch1 } from "../../store/user/action";
import Pagination from "../../pages/Pagination";
import Male from "../../assets/images/male.png"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";


const BlockUserData = () => {

    const dispatch = useDispatch();
    const { blockUser, totalBlockUser } = useSelector((state) => state.user);
    const [isExpanded, setIsExpanded] = useState(false);
    const maxLength = 25;


    const [data, setData] = useState([]);
    const history = useHistory();
    const [expandedReviews, setExpandedReviews] = useState({});
    const toggleReview = (index) => {
        setExpandedReviews((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };




    const [activePage, setActivePage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [search, setSearch] = useState("ALL");

    console.log("blockUser", blockUser)

    useEffect(() => {
        const payload = {
            start: activePage,
            limit: rowsPerPage,
            search,


        }
        dispatch(getBlockUser(payload))

    }, [activePage, rowsPerPage, search])

    useEffect(() => {
        setData(blockUser)
    }, [blockUser])

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
    };

    const handleRowsPerPage = (value) => {
        setActivePage(1);
        setRowsPerPage(value);
    };

    const handleUnblock = (userId) => {

        dispatch(handleBlockUnblockSwitch1(userId))
    }

    const handleUserInfo = (user) => {
        localStorage.setItem("user", JSON.stringify(user));
        history.push("/admin/user/detail");
    };
    const handleUserHistory = (user) => {
        localStorage.setItem("user", JSON.stringify(user));
        history.push("/admin/user/history");
    };




    return (
        <>
            <div className="page-title">
                <div className="row">
                    <div className="col-12 col-md-6 order-md-1 order-last">
                        <h3 className="mb-3 text-white">Block User</h3>
                    </div>
                    <div className="col-12 col-md-6 order-md-2 order-first">
                        <nav
                            aria-label="breadcrumb"
                            className="breadcrumb-header float-start float-lg-end"
                        >
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to="/admin/dashboard" className="text-danger">
                                        Dashboard
                                    </Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Block User
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>

            <div class="card">

                <div className="row">
                    <div className="col-8"></div>
                    <div className="col-4"
                    >
                        <form action="" style={{
                            paddingRight: "20px",
                            paddingTop: "20px"
                        }}>
                            <div className="input-group mb-3 border rounded-pill">
                                <input
                                    type="search"
                                    id="searchBar"
                                    autoComplete="off"
                                    placeholder="What're you searching for?"
                                    aria-describedby="button-addon4"
                                    className="form-control bg-none border-0 rounded-pill searchBar"
                                    onChange={(e) => {
                                        if (e.target.value.length === 0) {
                                            setSearch(e.target.value);
                                            setActivePage(1);
                                        }
                                    }}
                                    onKeyPress={(e) => {
                                        if (e.key === "Enter") {
                                            e.preventDefault();
                                            setSearch(e.target.value);
                                            setActivePage(1);
                                        }
                                    }}
                                />
                                <div
                                    className="input-group-prepend border-0"
                                    for="searchBar"
                                    onClick={() => {
                                        // Use setSearch with the value of the input field
                                        setSearch(document.getElementById("searchBar").value);
                                        setActivePage(1);
                                    }}
                                >
                                    <div id="button-addon4" className="btn text-danger">
                                        <i className="fas fa-search mt-2"></i>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="card-header pb-0 pt-0">
                    <div className="row my-3">

                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 float-right mt-3 mt-lg-0 mt-xl-0">
                            {/* <form action="">
                <div className="input-group mb-3 border rounded-pill">
                  <div className="input-group-prepend border-0">
                    <div id="button-addon4" className="btn text-danger">
                      <i className="fas fa-search mt-2"></i>
                    </div>
                  </div>
                  <input
                    type="search"
                    placeholder="What're you searching for?"
                    aria-describedby="button-addon4"
                    className="form-control bg-none border-0 rounded-pill searchBar"
                    onChange={handleSearch}
                  />
                </div>
              </form> */}
                        </div>
                    </div>
                </div>
                <div class="card-body card-overflow pt-0">
                    <table class="table table-striped mt-5 text-center">
                        <thead>
                            <tr>
                                <th>No.</th>
                                <th>Image</th>
                                <th>Name</th>
                                <th>UniqueId</th>
                                <th>Country</th>
                                <th>Level</th>
                                <th>Block Reason</th>
                                <th>Block Person</th>
                                <th>Date</th>
                                <th>isBlock</th>
                                <th>Info</th>
                                <th>History</th>

                            </tr>
                        </thead>
                        <tbody>
                            {data.length > 0 ? (
                                data.map((data, index) => {

                                    const isExpanded = expandedReviews[index];
                                    const reviewText = data?.blockReason || "-";
                                    const previewText = reviewText?.substring(0, 7); // Adjust length as needed
                                    return (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>
                                                <img
                                                    height="50px"
                                                    width="50px"
                                                    alt="app"
                                                    src={data.image ? data.image : Male}
                                                    style={{
                                                        boxShadow: "0 5px 15px 0 rgb(105 103 103 / 0%)",
                                                        border: "2px solid #fff",
                                                        borderRadius: 10,
                                                        objectFit: "cover",
                                                        display: "block",
                                                    }}
                                                    className="mx-auto"
                                                />
                                            </td>
                                            <td>{data.name ? data.name : "-"}</td>
                                            <td>{data.uniqueId ? data.uniqueId : "-"}</td>
                                            <td className="text-success">
                                                {data.country ? data.country : "-"}
                                            </td>
                                            <td className="text-warning">
                                                {data.level.name ? data.level.name : "-"}
                                            </td>



                                            <td>
                                                {isExpanded ? reviewText : reviewText.length > 30 ? previewText + "..." : reviewText}
                                                {reviewText.length > 30 && (
                                                    <span
                                                        style={{ color: "blue", cursor: "pointer", marginLeft: "5px" }}
                                                        onClick={() => toggleReview(index)}
                                                    >
                                                        {isExpanded ? " Read Less" : " Read More"}
                                                    </span>
                                                )}
                                            </td>

                                            <td>
                                                {data.blockPerson ? data.blockPerson : "-"}
                                            </td>
                                            <td>{data.blockDate?.split(",")[0] || "-"}</td>
                                            <td>
                                                <label className="switch">
                                                    <input
                                                        type="checkbox"
                                                        checked={data.isBlock}
                                                        onChange={() =>
                                                            handleUnblock(data?._id)
                                                        }
                                                    />
                                                    <span className="slider">
                                                        <p
                                                            style={{
                                                                fontSize: 12,
                                                                marginLeft: `${data.isBlock ? "-24px" : "35px"
                                                                    }`,
                                                                color: "#000",
                                                                marginTop: "6px",
                                                            }}
                                                        >
                                                            {data.isBlock === true ? "Yes" : "No"}
                                                        </p>
                                                    </span>
                                                </label>
                                            </td>
                                            <td>
                                                <Tooltip title="Info">
                                                    <button
                                                        type="button"
                                                        className="btn btn-sm btn-info"
                                                        onClick={() => handleUserInfo(data)}
                                                    >
                                                        <i className="fas fa-info-circle fa-lg"></i>
                                                    </button>
                                                </Tooltip>
                                            </td>
                                            <td>
                                                <Tooltip title="History">
                                                    <button
                                                        type="button"
                                                        className="btn btn-sm btn-success"
                                                        onClick={() => handleUserHistory(data)}
                                                    >
                                                        <i className="fas fa-history fa-lg"></i>
                                                    </button>
                                                </Tooltip>
                                            </td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr>
                                    <td colSpan="12" align="center">
                                        Nothing to show!!
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>

                    <Pagination
                        activePage={activePage}
                        rowsPerPage={rowsPerPage}
                        userTotal={totalBlockUser}
                        handleRowsPerPage={handleRowsPerPage}
                        handlePageChange={handlePageChange}
                    />
                </div>
            </div>
        </>
    )
}

export default BlockUserData