import React, { useEffect } from "react";

// js
import "../assets/js/main.min.js";

//router
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";

// css
import "../assets/css/main.min.css";
import "../assets/css/custom.css";

// component
import Navbar from "../component/navbar/Navbar";
import Topnav from "../component/navbar/Topnav";
import CoinPlanTable from "../component/table/CoinPlan";
import PurchaseCoinPlanHistoryTable from "../component/table/PurchaseCoinPlanHistory";
import VIPPlanTable from "../component/table/VIPPlan";
import PurchaseVIPPlanTable from "../component/table/PurchaseVipPlanHistory";
import GiftCategoryTable from "../component/table/GiftCategory";
import GiftTable from "../component/table/Gift";
import SongTable from "../component/table/Song";
import SongDialog from "../component/dialog/Song";
import GameTable from "../component/table/Game";
import GiftDialog from "../component/dialog/Gift/Add";
import HashtagTable from "../component/table/Hashtag";
import LevelTable from "../component/table/Level";
import UserTable from "../component/table/User";
import PostTable from "../component/table/Post";
import VideoTable from "../component/table/Video";
import UserDetail from "./UserDetail";
import UserHistory from "./UserHistory";
import PostDetail from "./PostDetail";
import VideoDetail from "./VideoDetail";
import Dashboard from "./Dashboard";
import Setting from "./Settings";
import ThemeTable from "../component/table/Theme.js";
import Advertisement from "../component/table/Advertisement";
import PendingComplainTable from "../component/table/PendingComplain";
import SolvedComplainTable from "../component/table/SolvedComplain";
import ReportedUserTable from "../component/table/ReportedUser";
import StickerTable from "../component/table/Sticker";
import FakeUser from "../component/table/FakeUser";
import FakeUserPage from "../component/dialog/FakeUserPage";
import FakePost from "../component/table/FakePost";
import FakeVideo from "../component/table/FakeVideo";
import FakePostPage from "../component/dialog/FakePostPage";
import FakeVideoPage from "../component/dialog/FakeVideoPage";

//loader
import Spinner from "./Spinner";
import Profile from "./Profile.js";
import FakeComment from "../component/table/FakeComment.js";
import GameHistory from "../component/table/GameHistory.js";
import Avatar from "../component/table/Avatar.js";
import AdmissionCar from "../component/table/AdmissionCar.js";
import FakePkUserPage from "../component/dialog/FakePkUserPage.js";
import FakeAudioUserPage from "../component/dialog/FakeAudioUserPage.js";
import UserRedeemRequest from "../component/table/userRedeem/UserRedeemRequest.js";
import Agency from "./Agency.js";
import AgencyWiseHost from "./AgencyWiseHost.js";
import HostRequest from "../component/table/hostRequest/HostRequest.js";
import CommissionMain from "../component/table/CommisionMain.js";
import CoinSeller from "./CoinSeller.js";
import SuperSeller from "./SuperSeller.js";

import CoinSellerHistory from "../component/table/CoinSellerHistory.js";
import AllSettlement from "../component/table/settlement/AllSettlement.js";
import SettlementPending from "../component/table/settlement/SettlementPending.js";
import SettlementSolved from "../component/table/settlement/SettlementSolved.js";

import HostAllSettlement from "../component/table/hostSettlement/AllSettlement.js";
import HostSettlementPending from "../component/table/hostSettlement/SettlementPending.js";
import HostSettlementSolved from "../component/table/hostSettlement/SettlementSolved.js";
import AgencyWeekSettlement from "../component/table/settlement/AgencyWeekSettlement.js";
import HostWeekSettlement from "../component/table/hostSettlement/HostWeekSettlement.js";
import Banner from "../component/table/Banner.js";
import Reaction from "../component/table/Reaction.js";
import SubAdmin from "../component/table/SubAdmin.js";
import Bd from "./Bd.js";
import BdWiseAgency from "./BdWiseAgency.js";
import BroadcastBanner from "../component/table/BroadCastBanner.js";
import BdSettlementPending from "../component/table/bdSettlement/BdSettlementPending.js";
import BdSettlementSolved from "../component/table/bdSettlement/BdSettlementSolved.js";
import VerfiedAgency from "./VerfiedAgency.js";
import UnverfiedAgency from "./UnverfiedAgency.js";
import SuperAdmin from "../component/table/SuperAdmin.js";
import SuperSellerHistory from "../component/table/SuperSellerHistory.js";
import SellerInfo from "../component/table/SellerInfo.js";
import HostLiveHistory from "../component/table/hostLiveHistory/HostLiveHistory.js";
import AgencyWiseLiveHistory from "./AgencyWiseLiveHistory.js";
import HostWiseLiveHistory from "./HostWiseLiveHistory.js";
import SuperAdminWiseBd from "../component/table/SuperAdminWiseBd.js";
import LiveUser from "../component/table/LiveUser.js";
import HostTask from "./HostTask.js";
import HostTaskHistory from "./HostTaskHistory.js";
import HostLevel from "../component/table/HostLevel.js";
import BlockUserData from "../component/table/BlockUserData.js";
import Hosts from "./Hosts.js";

const Admin = () => {
  const location = useRouteMatch();
  const history = useHistory();

  useEffect(() => {
    if (
      history.location.pathname === "/admin" ||
      history.location.pathname === "/admin/dashboard"
    ) {
      history.push("/admin/dashboard");
    }
  }, [history]);

  return (
    <>
      <div class="page-container">
        <Navbar />
        <div class="page-content">
          <Topnav />
          <div class="main-wrapper">
            <Switch>
              <Route
                path={`${location.path}/dashboard`}
                exact
                component={Dashboard}
              />
              <Route
                path={`${location.path}/subadmin`}
                exact
                component={SubAdmin}
              />

              <Route
                path={`${location.path}/superadmin`}
                exact
                component={SuperAdmin}
              />

              <Route
                path={`${location.path}/banner`}
                exact
                component={Banner}
              />
              <Route
                path={`${location.path}/broadCast`}
                exact
                component={BroadcastBanner}
              />
              <Route path={`${location.path}/bd`} exact component={Bd} />
              <Route
                path={`${location.path}/bd/bdWiseagency`}
                exact
                component={BdWiseAgency}
              />

              <Route
                path={`${location.path}/adminProfile`}
                exact
                component={Profile}
              />

              <Route
                path={`${location.path}/coinplan`}
                exact
                component={CoinPlanTable}
              />
              <Route
                path={`${location.path}/coinplan/history`}
                exact
                component={PurchaseCoinPlanHistoryTable}
              />
              <Route
                path={`${location.path}/vipplan`}
                exact
                component={VIPPlanTable}
              />
              <Route
                path={`${location.path}/vipplan/history`}
                exact
                component={PurchaseVIPPlanTable}
              />
              <Route
                path={`${location.path}/giftCategory`}
                exact
                component={GiftCategoryTable}
              />
              <Route
                path={`${location.path}/gameHistory`}
                exact
                component={GameHistory}
              />

              <Route
                path={`${location.path}/reaction`}
                exact
                component={Reaction}
              />

              <Route
                path={`${location.path}/allSettlement`}
                exact
                component={AllSettlement}
              />

              <Route
                path={`${location.path}/pending`}
                exact
                component={SettlementPending}
              />

              <Route
                path={`${location.path}/solved`}
                exact
                component={SettlementSolved}
              />
              <Route
                path={`${location.path}/liveUser`}
                exact
                component={LiveUser}
              />

              <Route
                path={`${location.path}/hostallSettlement`}
                exact
                component={HostAllSettlement}
              />

              <Route
                path={`${location.path}/hostpending`}
                exact
                component={HostSettlementPending}
              />

              <Route
                path={`${location.path}/hostsolved`}
                exact
                component={HostSettlementSolved}
              />

              <Route
                path={`${location.path}/bdpending`}
                exact
                component={BdSettlementPending}
              />

              <Route
                path={`${location.path}/bdsolved`}
                exact
                component={BdSettlementSolved}
              />

              <Route
                path={`${location.path}/allSettlement`}
                exact
                component={AllSettlement}
              />

              <Route
                path={`${location.path}/weeklySettlement`}
                exact
                component={AgencyWeekSettlement}
              />

              <Route
                path={`${location.path}/hostweeklySettlement`}
                exact
                component={HostWeekSettlement}
              />

              <Route
                path={`${location.path}/agency`}
                exact
                component={Agency}
              />
              <Route
                path={`${location.path}/verified`}
                exact
                component={VerfiedAgency}
              />

              <Route
                path={`${location.path}/superAdmin/bd`}
                exact
                component={SuperAdminWiseBd}
              />

              <Route
                path={`${location.path}/unverified`}
                exact
                component={UnverfiedAgency}
              />
              <Route
                path={`${location.path}/agency/agencyWiseHost`}
                exact
                component={AgencyWiseHost}
              />
              <Route
                path={`${location.path}/agency/agencyWiseLiveHistory`}
                exact
                component={AgencyWiseLiveHistory}
              />
              <Route
                path={`${location.path}/agency/HostWiseLiveHistory`}
                exact
                component={HostWiseLiveHistory}
              />
              <Route
                path={`${location.path}/theme`}
                exact
                component={ThemeTable}
              />
              <Route
                path={`${location.path}/giftCategory/gift`}
                exact
                component={GiftTable}
              />
              <Route
                path={`${location.path}/giftCategory/gift/dialog`}
                exact
                component={GiftDialog}
              />
              <Route
                path={`${location.path}/reaction`}
                exact
                component={Reaction}
              />
              <Route
                path={`${location.path}/gift`}
                exact
                component={GiftTable}
              />
              <Route
                path={`${location.path}/gift/dialog`}
                exact
                component={GiftDialog}
              />
              <Route
                path={`${location.path}/song`}
                exact
                component={SongTable}
              />
              <Route
                path={`${location.path}/song/dialog`}
                exact
                component={SongDialog}
              />
              <Route
                path={`${location.path}/hashtag`}
                exact
                component={HashtagTable}
              />
              <Route
                path={`${location.path}/level`}
                exact
                component={LevelTable}
              />

              <Route
                path={`${location.path}/hostLevel`}
                exact
                component={HostLevel}
              />
              <Route
                path={`${location.path}/user`}
                exact
                component={UserTable}
              />

              <Route
                path={`${location.path}/showblockuserdata`}
                exact
                component={BlockUserData}
              />
              <Route
                path={`${location.path}/fakeUser`}
                exact
                component={FakeUser}
              />
              <Route
                path={`${location.path}/fake/fakeUserdialog`}
                exact
                component={FakeUserPage}
              />
              <Route
                path={`${location.path}/fake/fakePkUserdialog`}
                exact
                component={FakePkUserPage}
              />
              <Route
                path={`${location.path}/fake/fakeAudioUserdialog`}
                exact
                component={FakeAudioUserPage}
              />
              <Route
                path={`${location.path}/user/detail`}
                exact
                component={UserDetail}
              />
              <Route
                path={`${location.path}/user/history`}
                exact
                component={UserHistory}
              />

              <Route
                path={`${location.path}/post`}
                exact
                component={PostTable}
              />
              <Route
                path={`${location.path}/post/dialog`}
                exact
                component={FakePostPage}
              />
              <Route
                path={`${location.path}/post/detail`}
                exact
                component={PostDetail}
              />
              <Route
                path={`${location.path}/post/fake`}
                exact
                component={FakePost}
              />
              <Route
                path={`${location.path}/video`}
                exact
                component={VideoTable}
              />
              <Route
                path={`${location.path}/video/fake`}
                exact
                component={FakeVideo}
              />
              <Route
                path={`${location.path}/setting`}
                exact
                component={Setting}
              />
              <Route
                path={`${location.path}/video/detail`}
                exact
                component={VideoDetail}
              />
              <Route
                path={`${location.path}/reportedUser`}
                exact
                component={ReportedUserTable}
              />
              <Route
                path={`${location.path}/advertisement`}
                exact
                component={Advertisement}
              />
              <Route
                path={`${location.path}/pendingComplain`}
                exact
                component={PendingComplainTable}
              />
              <Route
                path={`${location.path}/solvedComplain`}
                exact
                component={SolvedComplainTable}
              />
              <Route
                path={`${location.path}/coinSeller`}
                exact
                component={CoinSeller}
              />
              <Route
                path={`${location.path}/superSeller`}
                exact
                component={SuperSeller}
              />
              <Route
                path={`${location.path}/coinSeller/history`}
                exact
                component={CoinSellerHistory}
              />

              <Route
                path={`${location.path}/superSeller/history`}
                exact
                component={SuperSellerHistory}
              />
              <Route
                path={`${location.path}/superSeller/info`}
                exact
                component={SellerInfo}
              />
              <Route
                path={`${location.path}/userRedeemRequest`}
                exact
                component={UserRedeemRequest}
              />
              <Route
                path={`${location.path}/hostRequest`}
                exact
                component={HostRequest}
              />

              <Route
                path={`${location.path}/commission`}
                exact
                component={CommissionMain}
              />

              <Route
                path={`${location.path}/sticker`}
                exact
                component={StickerTable}
              />
              <Route
                path={`${location.path}/post/dialog`}
                exact
                component={FakePostPage}
              />
              <Route
                path={`${location.path}/post/fake`}
                exact
                component={FakePost}
              />

              <Route
                path={`${location.path}/video/fake`}
                exact
                component={FakeVideo}
              />
              <Route
                path={`${location.path}/video/dialog`}
                exact
                component={FakeVideoPage}
              />
              <Route
                path={`${location.path}/comment`}
                exact
                component={FakeComment}
              />
              <Route
                path={`${location.path}/entryEffect`}
                exact
                component={AdmissionCar}
              />

              <Route
                path={`${location.path}/avatarFrame`}
                exact
                component={Avatar}
              />
              <Route
                path={`${location.path}/game`}
                exact
                component={GameTable}
              />
              <Route
                path={`${location.path}/hostLiveHistory`}
                exact
                component={HostLiveHistory}
              />
              <Route
                path={`${location.path}/hostTask`}
                exact
                component={HostTask}
              />
              <Route
                path={`${location.path}/hostTaskhistory`}
                exact
                component={HostTaskHistory}
              />
              <Route
                path={`${location.path}/host`}
                exact
                component={Hosts}
              />
            </Switch>
            {/* <Spinner /> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Admin;
