import { BLOCK_UNBLOCK_SWITCH, BLOCK_UNBLOCK_SWITCH1, GET_ALL_HOSTS } from "./types";

const initialState = {
    host: [],
    blockHost : [],
    total: 0,
};

const hostReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_HOSTS:
            return {
                ...state,
                host: action.payload.host,
                total: action.payload.total,
            };

        case BLOCK_UNBLOCK_SWITCH:
            console.log("action.payload.data", action.payload.data);

            return {
                ...state,
                host: state.host.filter(
                    (user) => user._id !== action.payload._id
                ),
            };

        case BLOCK_UNBLOCK_SWITCH1:
            return {
                ...state,
                blockHost: state.blockHost.filter(
                    (blockHost) => blockHost._id !== action.payload._id
                ),
            };
        default:
            return state;
    }
};

export default hostReducer;
