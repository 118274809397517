import axios from "axios";
import { Toast } from "../../util/Toast";

import { apiInstanceFetch } from "../../util/api";
import { CLOSE_LEVEL_DIALOG, CREATE_NEW_HOST_LEVEL, DELETE_HOST_LEVEL, EDIT_HOST_LEVEL, GET_HOST_LEVEL, PICK_COLOR } from "./types";


export const getHostLevel = () => (dispatch) => {
  apiInstanceFetch
    .get(`hostLevel`)
    .then((res) => {
      
      if (res.status) {
        dispatch({ type: GET_HOST_LEVEL, payload: res.level });
      } else {
        Toast("error", res.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};
export const createNewHostLevel = (data) => (dispatch) => {
  axios
    .post(`hostLevel`, data)
    .then((res) => {
      
      if (res.data.status) {
        Toast("success", "Host Level created successfully!");
        dispatch({ type: CLOSE_LEVEL_DIALOG });
        dispatch({ type: CREATE_NEW_HOST_LEVEL, payload: res.data.level });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};
export const editHostLevel = (levelId, data) => (dispatch) => {
  axios
    .patch(`hostLevel/${levelId}`, data)
    .then((res) => {
      if (res.data.status) {
        Toast("success", "Host Level updated successfully!");
        dispatch({ type: CLOSE_LEVEL_DIALOG });
        dispatch({
          type: EDIT_HOST_LEVEL,
          payload: { data: res.data.level, id: levelId },
        });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};
export const deleteHostLevel = (levelId) => (dispatch) => {
  axios
    .delete(`hostLevel/${levelId}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: DELETE_HOST_LEVEL, payload: levelId });
        Toast("success", "Host Level deleted successfully!");
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => console.log(error));
};



export const updateColor = (data) => (dispatch) => {
  // console.log(color)
  axios
    .patch(`hostLevel/updateCommentColor?levelId=${data.levelId}&commentColor=${data.commentColor}`)
    .then((res) => {
      if (res.data.status) {
        Toast("success", "Comment Color Changed!");
        dispatch({
          type: PICK_COLOR,
          payload: { color: data.commentColor, id: data.id },
        });
      } else Toast("error", res.data.message);
    })
    .catch((error) => Toast("error", error.message));
};
