import axios from "axios";
import {
  CREATE_TASK,
  DELETE_TASK,
  GET_HOST_TASK,
  GET_TASK_HISTORY,
  TOGGLE_ACTIVE,
} from "./types";
import { apiInstanceFetch } from "../../util/api";
import { Toast } from "../../util/Toast";

export const getHostTasks = () => (dispatch) => {
  apiInstanceFetch
    .get("task")
    .then((res) => {
      if (res.status) {
        dispatch({ type: GET_HOST_TASK, payload: res.data });
      }
    })
    .catch((error) => {
      Toast("error", error.message);
    });
};

export const createTask = (task) => (dispatch) => {
  apiInstanceFetch
    .post("task", task)
    .then((res) => {
      if (res.status) {
        dispatch({ type: CREATE_TASK, payload: res.task });
        Toast("sucess", "Task created successfully");
      }
    })
    .catch((error) => {
      Toast("error", error.message);
    });
};

export const toggleActive = (id) => (dispatch) => {
  apiInstanceFetch
    .patch(`task/isActive?taskId=${id}`)
    .then((res) => {
      if (res.status) {
        dispatch({ type: TOGGLE_ACTIVE, payload: res.data });
      }
    })
    .catch((error) => {
      Toast("error", error.message);
    });
};

export const deleteTask = (id) => (dispatch) => {
  apiInstanceFetch.delete(`task?taskId=${id}`).then((res) => {
    if (res.status) {
      dispatch({ type: DELETE_TASK, payload: id });
    }
  });
};

export const getTaskHistory = (start, limit, search) => (dispatch) => {
  apiInstanceFetch
    .get(`task/taskHistoryForAdmin?start=${start}&limit=${limit}&search=${search}`)
    .then((res) => {
      if (res.status) {
        console.log(res)
        dispatch({ type: GET_TASK_HISTORY, payload: res });
      }
    }).catch((error) => Toast("error", error.message));
};
