import axios from "axios";
import { Toast } from "../../util/Toast";
import {
  BLOCK_UNBLOCK_SWITCH,
  GET_USER,
  GET_HISTORY,
  EDIT_COIN,
  REMOVE_LIVEUSER,
  GET_LIVEUSER,
  EDIT_LIVE_TYPE,
  GET_BLOCK_USER,
  BLOCK_UNBLOCK_SWITCH1,
} from "./types";
import { baseURL, key } from "../../util/Config";
import { apiInstanceFetch } from "../../util/api";

export const getUser =
  (start, limit, searchValue, sDate, eDate) => (dispatch) => {
    apiInstanceFetch
      .get(
        `user/getUsers?start=${start}&limit=${limit}&search=${searchValue}&startDate=${sDate}&endDate=${eDate}`
      )
      .then((res) => {
        if (res.status) {
          let male, female;
          if (res.maleFemale) {
            res.maleFemale.map((data) => {
              if (data._id === "female") return (female = data.gender);
              if (data._id === "male") return (male = data.gender);
            });
          }
          dispatch({
            type: GET_USER,
            payload: {
              user: res.user,
              activeUser: res.activeUser,
              total: res.total,
              male: male,
              female: female,
            },
          });
        } else {
          Toast("error", res.message);
        }
      })
      .catch((error) => Toast("error", error.message));
  };

  export const getBlockUser = (payload) => (dispatch) => {
    apiInstanceFetch
      .get(`user/blockedUsers?start=${payload?.start}&limit=${payload?.limit}&search=${payload?.search}`)
      .then((res) => {
        
        if (res.status) {
          
          dispatch({
            type: GET_BLOCK_USER,
            payload: { data: res.user , total : res.total },
          });
        } else {
          Toast("error", res.data.message);
        }
      })
      .catch((error) => Toast("error", error.message));
  };

export const handleBlockUnblockSwitch = (payload,userId) => (dispatch) => {
  axios
    .patch(`user/blockUnblock/${userId}` , payload)
    .then((res) => {
      
      if (res.data.status) {
        dispatch({ type: BLOCK_UNBLOCK_SWITCH, payload: res.data.user });
        Toast("success" , "User block Succesfully")
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};

export const handleBlockUnblockSwitch1 = (userId) => (dispatch) => {
  axios
    .patch(`user/blockUnblock/${userId}`)
    .then((res) => {
      
      if (res.data.status) {
        dispatch({ type: BLOCK_UNBLOCK_SWITCH1, payload: res?.data?.user });
        Toast("success" , "User Unblock Succesfully")
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};



export const userHistory = (data) => (dispatch) => {
  axios
    .post(`history`, data)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: GET_HISTORY, payload: res.data });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};

export const editCoin = (data) => (dispatch) => {
  axios
    .post(`/user/addLessCoin`, data)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: EDIT_COIN,
          payload: { data: res.data.user, id: data.userId },
        });
        Toast("success", "Update Successful!!");
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};

export const getLiveUser = () => (dispatch) => {
  axios
    .get(`/liveUser/getLiveUserAdmin`)
    .then((res) => {
      
      if (res.data.status) {
        dispatch({
          type: GET_LIVEUSER,
          payload:res.data.data
        });
       
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};

export const removeLiveUser = (userId,liveStreamingId) => (dispatch) => {
  axios
    .post(`/liveUser/liveStreamingCutByAdmin?userId=${userId}&liveStreamingId=${liveStreamingId}`)
    .then((res) => {
      
      if (res.data.status) {
        dispatch({
          type: REMOVE_LIVEUSER,
          payload:res.data.data
        });
        Toast("success", "Remove Successfully!!");
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};

export const editLiveType = (userId, type) => (dispatch) => {
  axios.patch(`user/updateLiveType?userId=${userId}&liveType=${type}`).then((res) => {
    if (res.data.status) {
      dispatch({
        type: EDIT_LIVE_TYPE,
        payload: {data: res.data.user, id: userId}
      });
      Toast("success", "type updated successfully!!");
    } else {
      Toast("error", res.data.message)
    }
  }).catch((error) => console.log(error.message))
}
