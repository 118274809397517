export const GET_HOST_TASK = "GET_HOST_TASK";

export const TOGGLE_ACTIVE = "TOGGLE_ACTIVE";

export const OPEN_TASK_DIALOGUE = "OPEN_TASK_DIALOGUE";

export const CLOSE_TASK_DIALOGUE = "CLOSE_TASK_DIALOGUE";

export const CREATE_TASK = "CREATE_TASK";

export const DELETE_TASK = "DELETE_TASK";

export const GET_TASK_HISTORY = "GET_TASK_HISTORY"
