import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
//routing
import { Link, useHistory } from "react-router-dom";

//MUI
import { Tooltip } from "@material-ui/core";

//image
import Male from "../assets/images/male.png";
import Pagination from "./Pagination";
import { getHosts, handleBlockUnblockSwitch } from "../store/host/action";
import { Toast } from "../util/Toast";
import { OPEN_REASON_DIALOGUE } from "../store/host/types";
import ReasonDialogue from "../component/table/hostRequest/ReasonDialogue";

const Hosts = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [data, setData] = useState([]);

    const [activePage, setActivePage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [search, setSearch] = useState("ALL");

    const { host, total } = useSelector((state) => state.host);

    useEffect(() => {
        dispatch(getHosts(activePage, rowsPerPage, search));
    }, [dispatch, activePage, rowsPerPage, search]);

    useEffect(() => {
        setData(host);
    }, [host]);

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
    };

    const handleRowsPerPage = (value) => {
        setActivePage(1);
        setRowsPerPage(value);
    };

    const handleBlockUnblockSwitch_ = (userId) => {
        try {
    
          dispatch({ type: OPEN_REASON_DIALOGUE, payload: { id: userId, type: "user_unblock_to_block" } });
    
        } catch (error) {
          Toast(error?.message)
        }
    
      };

    const handleUserInfo = (user) => {
        localStorage.setItem("user", JSON.stringify(user));
        history.push("/admin/user/detail");
    };
    const handleUserHistory = (user) => {
        localStorage.setItem("user", JSON.stringify(user));
        history.push("/admin/user/history");
    };

    return (
        <>
        <ReasonDialogue />
            <div className="page-title">
                <div className="row">
                    <div className="col-12 col-md-6 order-md-1 order-last">
                        <h3
                            className="mb-3 text-light"
                            style={{ color: "#e4eeff" }}
                        >
                            Hosts
                        </h3>
                    </div>
                    <div className="col-12 col-md-6 order-md-2 order-first">
                        <nav
                            aria-label="breadcrumb"
                            className="breadcrumb-header float-start float-lg-end"
                        >
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link
                                        to="/admin/dashboard"
                                        className="text-danger"
                                    >
                                        Dashboard
                                    </Link>
                                </li>
                                <li
                                    className="breadcrumb-item active "
                                    aria-current="page"
                                >
                                    Hosts
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="card" id="card">
                        <div className="card-header pb-0">
                            <div className="row my-3">
                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-8 float-left"></div>
                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 float-right">
                                    <form action="">
                                        <div className="input-group mb-3 border rounded-pill">
                                            <input
                                                type="search"
                                                id="searchBar"
                                                autoComplete="off"
                                                placeholder="What're you searching for?"
                                                aria-describedby="button-addon4"
                                                className="form-control bg-none border-0 rounded-pill searchBar"
                                                onChange={(e) => {
                                                    if (
                                                        e.target.value
                                                            .length === 0
                                                    ) {
                                                        setSearch(
                                                            e.target.value
                                                        );
                                                        setActivePage(1);
                                                    }
                                                }}
                                                onKeyPress={(e) => {
                                                    if (e.key === "Enter") {
                                                        e.preventDefault();
                                                        setSearch(
                                                            e.target.value
                                                        );
                                                        setActivePage(1);
                                                    }
                                                }}
                                            />
                                            <div
                                                className="input-group-prepend border-0"
                                                for="searchBar"
                                                onClick={() => {
                                                    // Use setSearch with the value of the input field
                                                    setSearch(
                                                        document.getElementById(
                                                            "searchBar"
                                                        ).value
                                                    );
                                                    setActivePage(1);
                                                }}
                                            >
                                                <div
                                                    id="button-addon4"
                                                    className="btn text-danger"
                                                >
                                                    <i className="fas fa-search mt-2"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div class="card-body card-overflow pt-0">
                            <table class="table table-striped mt-5 text-center">
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th>Image</th>
                                        <th>Name</th>
                                        <th>UniqueId</th>
                                        <th>Agency Name</th>
                                        <th>Agency Code</th>
                                        <th>BD Name</th>
                                        <th>BD Code</th>
                                        <th>Gender</th>
                                        <th>RCoin</th>
                                        <th>Country</th>
                                        <th>Level</th>
                                        <th>Follower</th>
                                        <th>Following</th>
                                        <th>isBlock</th>
                                        <th>Info</th>
                                        <th>History</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.length > 0 ? (
                                        data.map((data, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>
                                                        <img
                                                            height="50px"
                                                            width="50px"
                                                            alt="app"
                                                            src={
                                                                data.image
                                                                    ? data.image
                                                                    : Male
                                                            }
                                                            style={{
                                                                boxShadow:
                                                                    "0 5px 15px 0 rgb(105 103 103 / 0%)",
                                                                border: "2px solid #fff",
                                                                borderRadius: 10,
                                                                objectFit:
                                                                    "cover",
                                                                display:
                                                                    "block",
                                                            }}
                                                            className="mx-auto"
                                                        />
                                                    </td>
                                                    <td>
                                                        {data.name
                                                            ? data.name
                                                            : "-"}
                                                    </td>
                                                    <td>
                                                        {data.uniqueId
                                                            ? data.uniqueId
                                                            : "-"}
                                                    </td>
                                                    <td>
                                                        {data.agency?.name || "-"}
                                                    </td>
                                                    <td>
                                                        {data.agency?.agencyCode || "-"}
                                                    </td>
                                                    <td>
                                                        {data.bd?.name || "-"}
                                                    </td>
                                                    <td>
                                                        {data.bd?.bdCode || "-"}
                                                    </td>
                                                    <td>
                                                        {data.gender
                                                            ? data.gender
                                                            : "-"}
                                                    </td>
                                                    <td className="text-danger">
                                                        {data.rCoin
                                                            ? data.rCoin
                                                            : "0"}
                                                    </td>
                                                    <td className="text-success">
                                                        {data.country
                                                            ? data.country
                                                            : "-"}
                                                    </td>
                                                    <td className="text-warning">
                                                        {data.level.name
                                                            ? data.level.name
                                                            : "-"}
                                                    </td>
                                                    <td>
                                                        {data.followers
                                                            ? data.followers
                                                            : "0"}
                                                    </td>
                                                    <td>
                                                        {data.following
                                                            ? data.following
                                                            : "0"}
                                                    </td>
                                                    <td>
                                                        <label className="switch">
                                                            <input
                                                                type="checkbox"
                                                                checked={
                                                                    data.isBlock
                                                                }
                                                                onChange={() =>
                                                                  handleBlockUnblockSwitch_(data)
                                                                }
                                                            />
                                                            <span className="slider">
                                                                <p
                                                                    style={{
                                                                        fontSize: 12,
                                                                        marginLeft: `${
                                                                            data.isBlock
                                                                                ? "-24px"
                                                                                : "35px"
                                                                        }`,
                                                                        color: "#000",
                                                                        marginTop:
                                                                            "6px",
                                                                    }}
                                                                >
                                                                    {data.isBlock
                                                                        ? "Yes"
                                                                        : "No"}
                                                                </p>
                                                            </span>
                                                        </label>
                                                    </td>
                                                    <td>
                                                        <Tooltip title="Info">
                                                            <button
                                                                type="button"
                                                                className="btn btn-sm btn-info"
                                                                onClick={() => handleUserInfo(data)}
                                                            >
                                                                <i className="fas fa-info-circle fa-lg"></i>
                                                            </button>
                                                        </Tooltip>
                                                    </td>

                                                    <td>
                                                        <Tooltip title="History">
                                                            <button
                                                                type="button"
                                                                className="btn btn-sm btn-success"
                                                                onClick={() => handleUserHistory(data)}
                                                            >
                                                                <i className="fas fa-history fa-lg"></i>
                                                            </button>
                                                        </Tooltip>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    ) : (
                                        <tr>
                                            <td colSpan="13" align="center">
                                                Nothing to show!!
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>

                            <Pagination
                                activePage={activePage}
                                rowsPerPage={rowsPerPage}
                                userTotal={total}
                                handleRowsPerPage={handleRowsPerPage}
                                handlePageChange={handlePageChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Hosts;
