import {
  CLOSE_TASK_DIALOGUE,
  CREATE_TASK,
  DELETE_TASK,
  GET_HOST_TASK,
  GET_TASK_HISTORY,
  OPEN_TASK_DIALOGUE,
  TOGGLE_ACTIVE,
} from "./types";

const initialState = {
  tasks: [],
  taskHistory: [],
  total: 0,
  dialogueOpen: false,
  dialogueData: null,
};

export const hostTaskReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_HOST_TASK:
      return {
        ...state,
        tasks: action.payload,
      };
    case CREATE_TASK:
      return {
        ...state,
        tasks: [...state.tasks, action.payload],
      };
    case TOGGLE_ACTIVE:
      return {
        ...state,
        tasks: state.tasks.map((task) => {
          if (task._id === action.payload._id)
            return {
              ...task,
              isActive: action.payload.isActive,
            };
          else return task;
        }),
      };
    case OPEN_TASK_DIALOGUE:
      return {
        ...state,
        dialogueOpen: true,
        dialogueData: action.payload || null,
      };
    case CLOSE_TASK_DIALOGUE:
      return {
        ...state,
        dialogueOpen: false,
        dialogueData: null,
      };
    case DELETE_TASK:
      return {
        ...state,
        tasks: state.tasks.filter((task) => task._id !== action.payload),
      };
    case GET_TASK_HISTORY:
      return {
        ...state,
        taskHistory: action.payload.data,
        total: action.payload.total,
      };

    default:
      return state;
  }
};
