export const GET_USER = "GET_USER";
export const GET_BLOCK_USER = "GET_BLOCK_USER";

export const BLOCK_UNBLOCK_SWITCH = "BLOCK_UNBLOCK_SWITCH";
export const BLOCK_UNBLOCK_SWITCH1 = "BLOCK_UNBLOCK_SWITCH1";


export const GET_HISTORY = "GET_HISTORY";

export const EDIT_COIN = "EDIT_COIN";

export const GET_LIVEUSER = "GET_LIVEUSER";

export const REMOVE_LIVEUSER = "REMOVE_LIVEUSER";

export const EDIT_LIVE_TYPE = "EDIT_LIVE_TYPE"
