import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  deleteTask,
  getHostTasks,
  toggleActive,
} from "../store/hostTask/action";
import { OPEN_TASK_DIALOGUE } from "../store/hostTask/types";
import TaskDialogue from "../component/dialog/TaskDialogue";
import { Tooltip } from "antd";
import { warning } from "../util/Alert";

const HostTask = () => {
  const dispatch = useDispatch();
  const { tasks } = useSelector((state) => state.hostTask);
  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(getHostTasks());
  }, [dispatch]);

  useEffect(() => {
    setData(tasks);
  }, [tasks]);

  const handleBlockUnblockSwitch = (id) => {
    dispatch(toggleActive(id));
  };

  const handleOpen = () => {
    dispatch({ type: OPEN_TASK_DIALOGUE });
  };

  const handleDelete = (id) => {
    const data = warning();
    data.then((isDeleted) => {
      if (isDeleted) {
        dispatch(deleteTask(id));
      }
    });
  };

  return (
    <>
      <div className="page-title">
        <div className="row">
          <div className="col-12 col-md-6 order-md-1 order-last">
            <h3 className="mb-3 text-white">Host Task</h3>
          </div>
          <div className="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              className="breadcrumb-header float-start float-lg-end"
            >
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/admin/dashboard" className="text-danger">
                    Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Host Task
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="card" id="card">
            <div className="card-header pb-0">
              <div className="row my-3">
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-8 float-left">
                  <button
                    type="button"
                    className="btn waves-effect waves-light btn-danger btn-sm float-left"
                    onClick={handleOpen}
                    id="CoinSellerAdd"
                  >
                    <i className="fa fa-plus"></i>
                    <span className="icon_margin">New</span>
                  </button>
                </div>
              </div>
            </div>
            <div class="card-body card-overflowone pt-0">
              <table class="table table-striped card-overflow mt-5 text-center">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Type</th>
                    <th>Duration</th>
                    <th>Reward Coin</th>
                    <th>Earning Coin (Required)</th>
                    <th>Active</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {data.length > 0 ? (
                    data.map((data, index) => {
                      return (
                      <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{data.type}</td>
                          <td>{data.timeRequired} minutes</td>
                          <td>{data.coinsRewarded}</td>
                          <td>{data.coinRequired}</td>

                          <td>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={data.isActive}
                                onChange={() =>
                                  handleBlockUnblockSwitch(data._id)
                                }
                              />
                              <span className="slider">
                                <p
                                  style={{
                                    fontSize: 12,
                                    marginLeft: `${
                                      data.isActive ? "-24px" : "35px"
                                    }`,
                                    color: "#000",
                                    marginTop: "6px",
                                  }}
                                >
                                  {data.isActive ? "Yes" : "No"}
                                </p>
                              </span>
                            </label>
                          </td>
                          <td>
                            <Tooltip title="Delete">
                              <button
                                type="button"
                                className="btn btn-danger btn-sm"
                                onClick={() => handleDelete(data._id)}
                              >
                                <i className="fas fa-trash-alt"></i>
                              </button>
                            </Tooltip>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="12" align="center">
                        Nothing to show!!
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>

              {/* <Pagination
                activePage={activePage}
                rowsPerPage={rowsPerPage}
                userTotal={totalUser}
                handleRowsPerPage={handleRowsPerPage}
                handlePageChange={handlePageChange}
              /> */}
            </div>
          </div>
        </div>
      </div>
      <TaskDialogue />
    </>
  );
};

export default HostTask;
