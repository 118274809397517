import {GET_HOST_LEVEL , CREATE_NEW_HOST_LEVEL , EDIT_HOST_LEVEL , DELETE_HOST_LEVEL , OPEN_LEVEL_DIALOG , CLOSE_LEVEL_DIALOG , PICK_COLOR} from "./types"

const initialState = {
  hostLevel: [],
  dialog: false,
  dialogData: null,
};

const hostLevelReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_HOST_LEVEL:
      console.log("action.payload", action.payload)
      return {
        ...state,
        hostLevel: action.payload,
      };
    case CREATE_NEW_HOST_LEVEL:
      const data = [...state.hostLevel];
      data.unshift(action.payload);
      return {
        ...state,
        hostLevel: data,
      };
    case EDIT_HOST_LEVEL:
      return {
        ...state,
        hostLevel: state.hostLevel.map((hostLevel) => {
          if (hostLevel._id === action.payload.id) return action.payload.data;
          else return hostLevel;
        }),
      };
    case DELETE_HOST_LEVEL:
      return {
        ...state,
        hostLevel: state.hostLevel.filter((hostLevel) => hostLevel._id !== action.payload),
      };
    case OPEN_LEVEL_DIALOG:
      return {
        ...state,
        dialog: true,
        dialogData: action.payload || null,
      };
    case CLOSE_LEVEL_DIALOG:
      return {
        ...state,
        dialog: false,
        dialogData: null,
      };
    case PICK_COLOR:
      return {
        ...state,
        level: state.level.map((level) => {
          if (level._id === action.payload.id) {
            return {
              ...level,
              commentColor: action.payload.color,
            };
          }
          return level;
        }),
      };
    default:
      return state;
  }
};

export default hostLevelReducer;
