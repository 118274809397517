import {
  BLOCK_UNBLOCK_SWITCH,
  GET_USER,
  GET_HISTORY,
  EDIT_COIN,
  GET_LIVEUSER,
  REMOVE_LIVEUSER,
  EDIT_LIVE_TYPE,
  GET_BLOCK_USER,
  BLOCK_UNBLOCK_SWITCH1,
} from "./types";

const initialState = {
  user: [],
blockUser : [],
  male: 0,
  female: 0,
  totalUser: 0,
  activeUser: 0,
  history: [],
  totalHistoryUser: 0,
  income: 0,
  outgoing: 0,
  totalCallCharge: 0,
  liveStreamingIncome: 0,
  liveUser:[],
  totalBlockUser : 0

};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER:
      return {
        ...state,
        user: action.payload.user,
        male: action.payload.male,
        female: action.payload.female,
        totalUser: action.payload.total,
        activeUser: action.payload.activeUser,
      };
      case GET_BLOCK_USER :
          
        return {
          ...state,
          blockUser : action.payload.data,
          totalBlockUser : action.payload.total
        }
    case BLOCK_UNBLOCK_SWITCH:
    console.log("action.payload.data" , action.payload.data)

      return {
        ...state,
        user: state.user.filter((user) => user._id !== action.payload._id),
      };

      case BLOCK_UNBLOCK_SWITCH1:
        
    
          return {
            ...state,
            blockUser: state.blockUser.filter((blockUser) => blockUser._id !== action.payload._id),
          };

    case GET_HISTORY:
      return {
        ...state,
        history: action.payload.history,
        totalHistoryUser: action.payload.total,
        income: action.payload.incomeTotal,
        outgoing: action.payload.outgoingTotal,
        totalCallCharge: action.payload.totalCallCharge,
        liveStreamingIncome: action.payload.income,
      };
    case EDIT_COIN:
      return {
        ...state,
        user: state.user.map((user) => {
          if (user._id === action.payload.id) return action.payload.data;
          else return user;
        }),
      };
      case EDIT_LIVE_TYPE:
        return {
          ...state,
          user: state.user.map((user) => {
            if (user._id === action.payload.id) return action.payload.data;
            else return user;
          }),
        }


      case GET_LIVEUSER:
        return {
          ...state,
          liveUser:action.payload
        };
 

        case REMOVE_LIVEUSER:
          return {
            ...state,
            liveUser: state.liveUser.filter(
              (liveUser) => liveUser._id !== action.payload
            ),
          };
          

    default:
      return state;
  }
};

export default userReducer;
