import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { Cancel } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { CLOSE_TASK_DIALOGUE } from "../../store/hostTask/types";
import ReactSelect from "react-select";
import { createTask } from "../../store/hostTask/action";

const TaskDialogue = () => {
  const dispatch = useDispatch();
  const types = ["video", "audio"];
  const [type, setType] = useState(null);
  const [timeRequired, setTimeRequired] = useState("");
  const [coinsRewarded, setCoinsRewarded] = useState("");
  const [coinRequired , setCoinRequired] = useState("");
  const [errors, setErrors] = useState({
    type: "",
    timeRequired: "",
    coinsRewarded: "",
    coinRequired : ""
  });

  const { dialogueOpen: open, dialogData } = useSelector(
    (state) => state.hostTask
  );


  const closePopup = () => {
    dispatch({ type: CLOSE_TASK_DIALOGUE });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      type === null ||
      timeRequired === "" ||
      timeRequired < 0 ||
      coinsRewarded === "" ||
      coinsRewarded < 0 ||
      coinRequired < 0 ||
      coinRequired === ""
    ) {
      const error = {};
      if (type === null) error.type = "Type Is Required !";
      if (timeRequired === "") error.timeRequired = "duration Is Required !";
      if (timeRequired < 0)
        error.timeRequired = "Duration must be greater than 0";
      if (coinsRewarded === "")
        error.coinsRewarded = "Reward Coins Is Required !";
      if (coinsRewarded < 0) error.coin = "Enter Correct Reward Coins !";
      if (coinRequired < 0) error.coinRequired = "Coin must be greater than 0 !";
      if(coinRequired === "")  error.coinRequired = "Coin is Required !";
      
      return setErrors({ ...error });
    } else {
      const data = {
        type: type.value,
        timeRequired: timeRequired,
        coinsRewarded: coinsRewarded,
        coinRequired : coinRequired
      }
      dispatch(createTask(data))
      dispatch({type: CLOSE_TASK_DIALOGUE})
    }
  };

  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="responsive-dialog-title"
        onClose={closePopup}
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle id="responsive-dialog-title">
          <span className="text-danger font-weight-bold h4"> Host Task </span>
        </DialogTitle>

        <IconButton
          style={{
            position: "absolute",
            right: 0,
          }}
        >
          <Tooltip title="Close">
            <Cancel className="text-danger" onClick={closePopup} />
          </Tooltip>
        </IconButton>
        <DialogContent>
          <div className="modal-body pt-1 px-1 pb-3">
            <div className="d-flex flex-column">
              <form>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <label className="mb-2 text-gray">Type</label>

                      <ReactSelect
                        value={type}
                        options={types.map((option) => ({
                          value: option,
                          label:
                            option.charAt(0).toUpperCase() + option.slice(1),
                        }))}
                        onChange={(selectedOption) => {
                          setType(selectedOption);
                          if (!selectedOption) {
                            setErrors((prevErrors) => ({
                              ...prevErrors,
                              type: "Please select a type",
                            }));
                          } else {
                            setErrors((prevErrors) => ({
                              ...prevErrors,
                              type: "",
                            }));
                          }
                        }}
                        placeholder="Select a type"
                        isClearable
                      />

                      {errors.type && (
                        <div className="ml-2 mt-1">
                          <span className="text-red">{errors.type}</span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-12 mt-3">
                    <div
                      className="form-group"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <div style={{ width: "100%" }}>
                        <label className="mb-2 text-gray">Time</label>
                        <input
                          type="number"
                          className="form-control"
                          required=""
                          placeholder="Enter duration"
                          value={timeRequired}
                          onChange={(e) => {
                            setTimeRequired(e.target.value);
                            if (!e.target.value) {
                              return setErrors({
                                ...errors,
                                timeRequired: "time is Required!",
                              });
                            } else {
                              return setErrors({
                                ...errors,
                                timeRequired: "",
                              });
                            }
                          }}
                        />
                      </div>
                    </div>
                    {errors.timeRequired && (
                      <div className="ml-2 mt-1">
                        {errors.timeRequired && (
                          <div className="pl-1 text__left">
                            <span className="text-red">
                              {errors.timeRequired}
                            </span>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="col-12 mt-3">
                    <div className=" form-group">
                      <label className="mb-2 text-gray">Reward Coin</label>
                      <input
                        type="number"
                        className="form-control"
                        required=""
                        placeholder="Reward coins"
                        value={coinsRewarded}
                        onChange={(e) => {
                          setCoinsRewarded(e.target.value);
                          if (!e.target.value) {
                            return setErrors({
                              ...errors,
                              coinsRewarded: "Coin is Required!",
                            });
                          } else if (e.target.value < 0) {
                            return setErrors({
                              ...errors,
                              coinsRewarded: "Enter Correct Coin !",
                            });
                          } else {
                            return setErrors({
                              ...errors,
                              coinsRewarded: "",
                            });
                          }
                        }}
                      />
                      {errors.coinsRewarded && (
                        <div className="ml-2 mt-1">
                          {errors.coinsRewarded && (
                            <div className="pl-1 text__left">
                              <span className="text-red">
                                {errors.coinsRewarded}
                              </span>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-12 mt-3">
                    <div className=" form-group">
                      <label className="mb-2 text-gray">Earning Coin</label>
                      <input
                        type="number"
                        className="form-control"
                        required=""
                        placeholder="Reward coins"
                        value={coinRequired}
                        onChange={(e) => {
                          setCoinRequired(e.target.value);
                          if (!e.target.value) {
                            return setErrors({
                              ...errors,
                              coinRequired: "Coin is Required!",
                            });
                          } else if (e.target.value < 0) {
                            return setErrors({
                              ...errors,
                              coinRequired: "Enter Correct Coin !",
                            });
                          } else {
                            return setErrors({
                              ...errors,
                              coinRequired: "",
                            });
                          }
                        }}
                      />
                      {errors.coinRequired && (
                        <div className="ml-2 mt-1">
                          {errors.coinRequired && (
                            <div className="pl-1 text__left">
                              <span className="text-red">
                                {errors.coinRequired}
                              </span>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className={"mt-5"}>
                  <button
                    type="button"
                    className="btn btn-outline-info ml-2 btn-round float__right icon_margin"
                    onClick={closePopup}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-round float__right btn-danger"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default TaskDialogue;
