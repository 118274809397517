import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import MonthPicker from 'react-month-picker';
import 'react-month-picker/css/month-picker.css';
//Multi Select Dropdown
import Multiselect from "multiselect-react-dropdown";
// action
import {
  getSetting,
  updateSetting,
  handleSwitch,
  handleDownloadReport,
} from "../store/setting/action";

const Setting = (props) => {
  const dispatch = useDispatch();
  const [type, setType] = useState(() => {
    return localStorage.getItem('settingTab') || 'generalSetting';
  });
  const [mongoId, setMongoId] = useState("");
  const [referralBonus, setReferralBonus] = useState(0);
  const [referralCoinBonus, setReferralCoinBonus] = useState(0);
  const [loginBonus, setLoginBonus] = useState(0);
  const [agoraKey, setAgoraKey] = useState("");
  const [agoraCertificate, setAgoraCertificate] = useState("");
  const [maxSecondForVideo, setMaxSecondForVideo] = useState(0);
  const [privacyPolicyLink, setPrivacyPolicyLink] = useState("");
  const [privacyPolicyText, setPrivacyPolicyText] = useState("");
  const [femaleCallCharge, setFemaleCallCharge] = useState(0);
  const [maleCallCharge, setMaleCallCharge] = useState(0);
  const [femaleRandomCallRate, setFemaleRandomCallRate] = useState(0);
  const [maleRandomCallRate, setMaleRandomCallRate] = useState(0);
  const [bothRandomCallRate, setBothRandomCallRate] = useState(0);
  const [googlePlayEmail, setGooglePlayEmail] = useState("");
  const [googlePlayKey, setGooglePlayKey] = useState("");
  const [stripePublishableKey, setStripePublishableKey] = useState("");
  const [stripeSecretKey, setStripeSecretKey] = useState("");
  const [currency, setCurrency] = useState("$");
  const [bdCommsissionPercent, setBdCommsissionPercent] = useState("");
  const [rCoinForCaseOut, setRCoinForCaseOut] = useState(0);
  const [rCoinForDiamond, setRCoinForDiamond] = useState(0);
  const [isFake, setIsFake] = useState(false);
  const [googlePlaySwitch, setGooglePlaySwitch] = useState(false);
  const [stripeSwitch, setStripeSwitch] = useState(false);
  const [isAppActive, setIsAppActive] = useState(false);
  const [vipDiamond, setVipDiamond] = useState(0);

  const [minRCoinForCaseOut, setMinRCoinForCaseOut] = useState(0);
  const [paymentGateway, setPaymentGateway] = useState([]);
  const [selectedValue, setSelectedValue] = useState([]);
  const [gameCoin1, setGameCoin1] = useState(0);
  const [gameCoin2, setGameCoin2] = useState(0);
  const [gameCoin3, setGameCoin3] = useState(0);
  const [gameCoin4, setGameCoin4] = useState(0);
  const [gameCoin5, setGameCoin5] = useState(0);
  const [pkEndTime, setPkEndTime] = useState(300);
  const [pkPunishmentEndTime, setPkPunishmentEndTime] = useState(300);
  const [version, setVersion] = useState("");
  const [privateKey, setPrivateKey] = useState();
  const [coinForGameAnnouncement, setCoinForGameAnnouncement] = useState(0);
  const [coinForAllRoomAnnouncement, setCoinForAllRoomAnnouncement] =
    useState(0);
  const [callReceiverPercent, setCallReceiverPercent] = useState("");
  const pickerRef = useRef();



  const currentDate = new Date();
  const [selectedMonth, setSelectedMonth] = useState({
    year: currentDate.getFullYear(),
    month: currentDate.getMonth(), // Start with last month
    startDate: new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1).toLocaleDateString("en-CA"),
    endDate: new Date(currentDate.getFullYear(), currentDate.getMonth(), 0).toLocaleDateString("en-CA")
  });
  const [errors, setError] = useState({
    referralBonus: "",
    referralCoinBonus: "",
    loginBonus: "",
    maxSecondForVideo: "",
    callCharge: "",
    rCoinForCaseOut: "",
    rCoinForDiamond: "",
    minRCoinForCaseOut: "",
    maleCallCharge: "",
    femaleCallCharge: "",
    maleRandomCallRate: "",
    femaleRandomCallRate: "",
    bothRandomCallRate: "",
    vipDiamond: "",
    privateKey: "",
    bdCommsissionPercent: "",
    pkPunishmentEndTime: "",
    callReceiverPercent: "",
  });

  useEffect(() => {
    dispatch(getSetting());
  }, [dispatch]);

  const setting = useSelector((state) => state.setting.setting);

  useEffect(() => {
    setError({
      referralBonus: "",
      referralCoinBonus: "",
      loginBonus: "",
      maxSecondForVideo: "",
      callCharge: "",
      rCoinForCaseOut: "",
      rCoinForDiamond: "",
      minRCoinForCaseOut: "",
      pkEndTime: "",
      femaleCallCharge: "",
      femaleRandomCallRate: "",
      bothRandomCallRate: "",
      maleRandomCallRate: "",
      maleCallCharge: "",
      vipDiamond: "",
      privateKey: "",
      bdCommsissionPercent: "",
      pkPunishmentEndTime: "",
      callReceiverPercent: "",
    });
    if (setting) {
      const data = setting?.paymentGateway?.map((data) => {
        return {
          name: data,
        };
      });
      if (setting?.gameCoin?.length > 0) {
        setGameCoin1(setting?.gameCoin[0]);
        setGameCoin2(setting?.gameCoin[1]);
        setGameCoin3(setting?.gameCoin[2]);
        setGameCoin4(setting?.gameCoin[3]);
        setGameCoin5(setting?.gameCoin[4]);
      }
      setIsFake(setting?.isFake);
      setMongoId(setting._id);
      setVipDiamond(setting?.vipDiamond);
      setReferralBonus(setting.referralBonus);
      setReferralCoinBonus(setting.referralCoinBonus)
      setAgoraKey(setting.agoraKey);
      setAgoraCertificate(setting.agoraCertificate);
      setMaxSecondForVideo(setting.maxSecondForVideo);
      setPrivacyPolicyLink(setting.privacyPolicyLink);
      setPrivacyPolicyText(setting.privacyPolicyText);
      setMaleCallCharge(setting?.maleCallCharge);
      setFemaleCallCharge(setting?.femaleCallCharge);
      setMaleRandomCallRate(setting?.maleRandomCallRate)
      setFemaleRandomCallRate(setting?.femaleRandomCallRate)
      setBothRandomCallRate(setting?.bothRandomCallRate)
      setGooglePlayEmail(setting.googlePlayEmail);
      setGooglePlayKey(setting.googlePlayKey);
      setStripePublishableKey(setting.stripePublishableKey);
      setStripeSecretKey(setting.stripeSecretKey);
      setCurrency(setting.currency);
      setRCoinForCaseOut(setting.rCoinForCashOut);
      setRCoinForDiamond(setting.rCoinForDiamond);
      setGooglePlaySwitch(setting.googlePlaySwitch);
      setStripeSwitch(setting.stripeSwitch);
      setIsAppActive(setting.isAppActive);
      setLoginBonus(setting.loginBonus);
      setMinRCoinForCaseOut(setting.minRcoinForCashOut);
      setPaymentGateway(setting.paymentGateway);
      setPkEndTime(setting?.pkEndTime);
      setBdCommsissionPercent(setting?.bdCommsissionPercent);
      setSelectedValue(data);
      setPrivateKey(JSON.stringify(setting?.privateKey));
      setPkPunishmentEndTime(setting?.pkPunishmentEndTime);
      setCoinForAllRoomAnnouncement(setting?.coinForAllRoomAnnouncement);
      setCoinForGameAnnouncement(setting?.coinForGameAnnouncement)
      setVersion(setting?.version);
      setCallReceiverPercent(setting?.callReceiverPercent);
    }
  }, [setting]);


  const handleTabChange = (newType) => {
    setType(newType);
    localStorage.setItem('settingTab', newType);
    // if (newType === 'redeemSetting') {
    //   dispatch(getRedeemOptionsDropdown());
    // }
  };

  const handleMonthChange = (year, month) => {
    if (year > currentDate.getFullYear() || (year === currentDate.getFullYear() && month >= currentDate.getMonth() + 1)) {
      return; // Block future months
    }
    const startDate = new Date(year, month - 1, 1).toLocaleDateString("en-CA");
    const endDate = new Date(year, month, 0).toLocaleDateString("en-CA");
    setSelectedMonth({ year, month, startDate, endDate });
  };

  const openPicker = () => {
    pickerRef.current.show();
  };

  const handleDownload = () => {
    if (!selectedMonth?.startDate || !selectedMonth?.endDate) return;

    // ✅ Show toast immediately

    // ✅ Stop loader immediately
    dispatch({ type: "STOP_LOADER" });

    const payload = {
      startDate: selectedMonth.startDate,
      endDate: selectedMonth.endDate
    };

    dispatch(handleDownloadReport(payload));
  };

  // ✅ Disable download button if selected month is current/future
  const isFutureOrCurrentMonth = () => {
    const today = new Date();
    const selectedDate = new Date(selectedMonth.year, selectedMonth.month - 1); // Month is 0-based in JS

    return selectedDate >= new Date(today.getFullYear(), today.getMonth()); // Check if it's current or future month
  };



  const handleSubmit = () => {
    if (gameCoin1 < 0) {
      return setError({
        ...errors,
        gameCoin1: " Game Diamond Invalid Value!! ",
      });
    }

    if (gameCoin2 < 0) {
      return setError({
        ...errors,
        gameCoin2: " Game Diamond Invalid Value!! ",
      });
    }
    if (gameCoin3 < 0) {
      return setError({
        ...errors,
        gameCoin3: " Game Diamond Invalid Value!! ",
      });
    }
    if (gameCoin4 < 0) {
      return setError({
        ...errors,
        gameCoin4: " Game Diamond  Invalid Value!! ",
      });
    }

    if (gameCoin5 < 0) {
      return setError({
        ...errors,
        gameCoin5: " Game Diamond Invalid Value!! ",
      });
    }
    if (pkEndTime <= 0) {
      return setError({
        ...errors,
        pkEndTime: " pkEnd Time  Invalid Value!! ",
      });
    }

    if (pkPunishmentEndTime <= 0) {
      return setError({
        ...errors,
        pkPunishmentEndTime: " pkPunishmentEnd Time Invalid Value!! ",
      });
    }

    const vipDiamondValid = isNumeric(vipDiamond);
    if (!vipDiamondValid) {
      return setError({
        ...errors,
        vipDiamond: "Invalid Call Charge!!",
      });
    }

    const referralBonusValid = isNumeric(referralBonus);
    if (!referralBonusValid) {
      return setError({ ...errors, referralBonus: "Invalid Referral Bonus!!" });
    }
    const referralCoinBonusValid = isNumeric(referralCoinBonus);
    if (!referralCoinBonusValid) {
      return setError({ ...errors, referralCoinBonus: "Invalid referral Bonus Coin!!" });
    }
    const loginBonusValid = isNumeric(loginBonus);
    if (!loginBonusValid) {
      return setError({ ...errors, loginBonus: "Invalid Login Bonus!!" });
    }
    const maxSecondForVideoValid = isNumeric(maxSecondForVideo);
    if (!maxSecondForVideoValid) {
      return setError({
        ...errors,
        maxSecondForVideo: "Invalid Value!!",
      });
    }

    const femaleCallChargeValid = isNumeric(femaleCallCharge);
    if (!femaleCallChargeValid) {
      return setError({
        ...errors,
        femaleCallCharge: "Invalid Female  Call Charge!!",
      });
    }
    const maleCallChargeValid = isNumeric(maleCallCharge);
    if (!maleCallChargeValid) {
      return setError({
        ...errors,
        maleCallCharge: "Invalid Male Call Charge!!",
      });
    }
    const femaleRandomCallRateValid = isNumeric(femaleRandomCallRate);
    if (!femaleRandomCallRateValid) {
      return setError({
        ...errors,
        femaleRandomCallRate: 'Invalid Female Random Call Rate!!',
      });
    }
    const maleRandomCallRateValid = isNumeric(maleRandomCallRate);
    if (!maleRandomCallRateValid) {
      return setError({
        ...errors,
        maleRandomCallRate: 'Invalid male Random Call Rate!!',
      });
    }

    const bothRandomCallRateValid = isNumeric(bothRandomCallRate);
    if (!bothRandomCallRateValid) {
      return setError({
        ...errors,
        bothRandomCallRate: 'Invalid both Random Call Rate!!',
      });
    }

    const rCoinForCaseOutValid = isNumeric(rCoinForCaseOut);
    if (!rCoinForCaseOutValid) {
      return setError({
        ...errors,
        rCoinForCaseOut: "Invalid Value!!",
      });
    }
    const rCoinForDiamondValid = isNumeric(rCoinForDiamond);
    if (!rCoinForDiamondValid) {
      return setError({
        ...errors,
        rCoinForDiamond: "Invalid Value!!",
      });
    }

    const minRCoinForCaseOutValid = isNumeric(minRCoinForCaseOut);
    if (!minRCoinForCaseOutValid) {
      return setError({
        ...errors,
        minRCoinForCaseOut: "Invalid Value!!",
      });
    }

    const coinForGameAnnouncementValid = isNumeric(coinForGameAnnouncement);
    if (!coinForGameAnnouncementValid) {
      return setError({
        ...errors,
        coinForGameAnnouncement: "Invalid Value!!",
      });
    }
    const coinForAllRoomAnnouncementValid = isNumeric(
      coinForAllRoomAnnouncement
    );
    if (!coinForAllRoomAnnouncementValid) {
      return setError({
        ...errors,
        coinForAllRoomAnnouncement: "Invalid Value!!",
      });
    }
    if (!callReceiverPercent) {
      return setError({
        ...errors,
        callReceiverPercent: "Invalid Value!!",
      });
    }

    if (!coinForGameAnnouncement) {
      return setError({
        ...errors,
        coinForGameAnnouncement: "Invalid Value!!",
      });
    }

    let gameCoinArray = [gameCoin1, gameCoin2, gameCoin3, gameCoin4, gameCoin5];

    const data = {
      referralBonus,
      loginBonus,
      agoraKey,
      agoraCertificate,
      maxSecondForVideo: maxSecondForVideo === "" ? 0 : maxSecondForVideo,
      privacyPolicyLink,
      privacyPolicyText,
      // chatCharge: chatCharge === "" ? 0 : chatCharge,
      chatCharge: 0,
      femaleCallCharge: femaleCallCharge === "" ? 0 : femaleCallCharge,
      maleCallCharge: maleCallCharge === "" ? 0 : maleCallCharge,
      googlePlayEmail,
      googlePlayKey,
      stripePublishableKey,
      stripeSecretKey,
      currency,
      rCoinForCaseOut: rCoinForCaseOut === "" ? 0 : rCoinForCaseOut,
      rCoinForDiamond: rCoinForDiamond === "" ? 1 : rCoinForDiamond,
      paymentGateway,
      minRcoinForCaseOut: minRCoinForCaseOut,
      gameCoin: gameCoinArray,
      pkEndTime,
      pkPunishmentEndTime: parseInt(pkPunishmentEndTime),
      vipDiamond,
      privateKey,
      bdCommsissionPercent: parseInt(bdCommsissionPercent),
      coinForAllRoomAnnouncement,
      version,
      callReceiverPercent,
      coinForGameAnnouncement,
      femaleRandomCallRate,
      maleRandomCallRate,
      bothRandomCallRate,
      referralCoinBonus: Number(referralCoinBonus),
    };

    props.updateSetting(mongoId, data);
  };

  const handleSwitch_ = (type) => {
    props.handleSwitch(mongoId, type);
  };

  //onselect function of selecting multiple values
  function onSelect(selectedList, selectedItem) {
    paymentGateway.push(selectedItem.name);
  }

  //onRemove function for remove multiple values
  function onRemove(selectedList, removedItem) {
    setPaymentGateway(selectedList.map((data) => data.name));
  }

  const isNumeric = (value) => {
    const val = value === "" ? 0 : value;
    const validNumber = /^\d+$/.test(val);
    return validNumber;
  };

  const option = [
    { name: "Easypais" },
    { name: "Jazz cash" },
    { name: "Back account" },
  ];

  return (
    <>
      <div className="page-title">
        <div className="row">
          <div className="col-12 col-md-6 order-md-1 order-last">
            <h3 className="mb-5 text-white">Setting</h3>
          </div>
          <div className="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              className="breadcrumb-header float-start float-lg-end"
            >
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/admin/dashboard" className="text-danger">
                    Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Setting
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      {/* Tabs (Buttons) */}
      <div className="row mb-5">
        <div className="col-12">
          <div>
            <button
              type="button"
              className={`btn btn-sm m-2 ${type === 'generalSetting' ? 'btn-success' : 'disabledBtn'
                }`}
              onClick={() => handleTabChange('generalSetting')}
            >
              <span className="">General</span>
            </button>
            <button
              type="button"
              className={`btn btn-sm m-2 ${type === 'coinSetting' ? 'btn-success' : 'disabledBtn'
                }`}
              onClick={() => handleTabChange('coinSetting')}
            >
              <span className="">Coin</span>
            </button>
            <button
              type="button"
              className={`btn btn-sm m-2 ${type === 'agoraSetting' ? 'btn-success' : 'disabledBtn'
                }`}
              onClick={() => handleTabChange('agoraSetting')}
            >
              <span className="">Agora</span>
            </button>
            <button
              type="button"
              className={`btn btn-sm m-2 ${type === 'redeemOptions' ? 'btn-success' : 'disabledBtn'
                }`}
              onClick={() => {
                handleTabChange('redeemOptions');
              }}
            >
              <span className="">Redeem Options</span>
            </button>
            <button
              type="button"
              className={`btn btn-sm m-2 ${type === 'redeemSetting' ? 'btn-success' : 'disabledBtn'
                }`}
              onClick={() => handleTabChange('redeemSetting')}
            >
              <span className="">Redeem</span>
            </button>
            <button
              type="button"
              className={`btn btn-sm m-2 ${type === 'paymentSetting' ? 'btn-success' : 'disabledBtn'
                }`}
              onClick={() => {
                handleTabChange('paymentSetting');
              }}
            >
              <span className="">Payment</span>
            </button>
            <button
              type="button"
              className={`btn btn-sm m-2 ${type === 'gameSetting' ? 'btn-success' : 'disabledBtn'
                }`}
              onClick={() => handleTabChange('gameSetting')}
            >
              <span className="">Game</span>
            </button>
            <button
              type="button"
              className={`btn btn-sm m-2 ${type === 'firebaseSetting' ? 'btn-success' : 'disabledBtn'
                }`}
              onClick={() => handleTabChange('firebaseSetting')}
            >
              <span className="">Firebase</span>
            </button>
            <button
              type="button"
              className={`btn btn-sm m-2 ${type === 'otherSetting' ? 'btn-success' : 'disabledBtn'
                }`}
              onClick={() => handleTabChange('otherSetting')}
            >
              <span className="">Other</span>
            </button>
          </div>
        </div>
      </div>
      {/* Tabs */}

      {type === 'generalSetting' && (
        <div className="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div className="col-md-6 col-12">
                    <h5 class="card-title ">Other Setting</h5>
                  </div>
                  <div className="col-md-6 col-12 d-flex justify-content-between mb-0">
                    <h5 class="card-title ">Fake Data</h5>
                    <label class="switch">
                      <input
                        type="checkbox"
                        checked={isFake}
                        onChange={() => handleSwitch_("fake")}
                      />
                      <span class="slider">
                        <p
                          style={{
                            fontSize: 12,
                            marginLeft: `${isFake ? "7px" : "35px"}`,
                            color: `${isFake ? "#fff" : "#000"}`,
                            marginTop: "6px",
                          }}
                        >
                          {isFake ? "Yes" : "No"}
                        </p>
                      </span>
                    </label>
                  </div>
                  <form className="mt-3">
                    <div>

                      <div class="mb-3 row">
                        <div class="col-md-6">
                          <label for="referralBonus" class="form-label">
                            Referral Bonus ( Diamond )
                          </label>
                          <input
                            type="number"
                            class="form-control"
                            id="referralBonus"
                            value={referralBonus}
                            onChange={(e) => setReferralBonus(e.target.value)}
                          />
                          {errors.referralBonus && (
                            <div className="ml-2 mt-1">
                              {errors.referralBonus && (
                                <div className="pl-1 text__left">
                                  <span className="text-red">
                                    {errors.referralBonus}
                                  </span>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                        <div className="col-md-6">
                          <label for="referralCoinBonus" class="form-label">
                            Referral Bonus ( Coin )
                          </label>
                          <input
                            type="number"
                            class="form-control"
                            id="referralCoinBonus"
                            value={referralCoinBonus}
                            onChange={(e) => {
                              const value = Number(e.target.value); // Convert to Number
                              setReferralCoinBonus(value);
                            }}
                          />
                          {errors.referralCoinBonus && (
                            <div className="ml-2 mt-1">
                              {errors.referralCoinBonus && (
                                <div className="pl-1 text__left">
                                  <span className="text-red">
                                    {errors.referralCoinBonus}
                                  </span>
                                </div>
                              )}
                            </div>
                          )}
                        </div>

                      </div>
                      <div class="mb-3 row">
                        <div className="col-md-6">
                          <label for="videoSecond" class="form-label">
                            Maximum Seconds for Video
                          </label>
                          <input
                            type="number"
                            class="form-control"
                            id="videoSecond"
                            value={maxSecondForVideo}
                            onChange={(e) => setMaxSecondForVideo(e.target.value)}
                          />
                          {errors.maxSecondForVideo && (
                            <div className="ml-2 mt-1">
                              {errors.maxSecondForVideo && (
                                <div className="pl-1 text__left">
                                  <span className="text-red">
                                    {errors.maxSecondForVideo}
                                  </span>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                        <div className="col-md-6">
                          <label for="videoSecond" class="form-label">
                            App Version
                          </label>
                          <input
                            type="number"
                            class="form-control"
                            id="videoSecond"
                            value={version}
                            onChange={(e) => setVersion(e.target.value)}
                          />
                          {errors.maxSecondForVideo && (
                            <div className="ml-2 mt-1">
                              {errors.maxSecondForVideo && (
                                <div className="pl-1 text__left">
                                  <span className="text-red">
                                    {errors.maxSecondForVideo}
                                  </span>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                      <div class="mb-3 row">
                        <div class="col-md-6">
                          <label for="loginBonus" class="form-label">
                            Login Bonus
                          </label>
                          <input
                            type="number"
                            class="form-control"
                            id="loginBonus"
                            value={loginBonus}
                            onChange={(e) => setLoginBonus(e.target.value)}
                          />
                          {errors.loginBonus && (
                            <div className="ml-2 mt-1">
                              {errors.loginBonus && (
                                <div className="pl-1 text__left">
                                  <span className="text-red">
                                    {errors.loginBonus}
                                  </span>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="d-flex justify-content-end">
                      <button
                        type="button"
                        class="btn btn-danger "
                        onClick={handleSubmit}
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <h5 class="card-title d-flex justify-content-between mb-3">
                    Is App Active (use at the time of app maintenance)
                    <label class="switch">
                      <input
                        type="checkbox"
                        checked={isAppActive}
                        onChange={() => handleSwitch_("app active")}
                      />
                      <span class="slider">
                        <p
                          style={{
                            fontSize: 12,
                            marginLeft: `${isAppActive ? "7px" : "35px"}`,
                            color: `${isAppActive ? "#fff" : "#000"}`,
                            marginTop: "6px",
                          }}
                        >
                          {isAppActive ? "Yes" : "No"}
                        </p>
                      </span>
                    </label>
                  </h5>

                  <form>
                    <div class="mb-3">
                      <label for="policyLink" class="form-label">
                        Privacy Policy Link (redirect to this link in app in
                        privacy policy click)
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="policyLink"
                        value={privacyPolicyLink}
                        onChange={(e) => setPrivacyPolicyLink(e.target.value)}
                      />
                    </div>
                    <div class="mb-2">
                      <label for="policyText" class="form-label">
                        Privacy Policy Text
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="policyText"
                        value={privacyPolicyText}
                        onChange={(e) => setPrivacyPolicyText(e.target.value)}
                      />
                    </div>
                    <div className="d-flex justify-content-end">
                      <button
                        type="button"
                        class="btn btn-danger "
                        onClick={handleSubmit}
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {type === 'coinSetting' && (
        <>
          <div className="row">
            <div class="col-12">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div className="col-md-6 col-6">
                      <h5 class="card-title ">Random Call Charges</h5>
                      <form>
                        <div class="mb-3 row">
                          <div class="col-md-6">
                            <label for="femaleRandomCallRate" class="form-label">
                              Female Random Call Charge per min
                            </label>
                            <input
                              type="number"
                              class="form-control"
                              id="femaleRandomCallRate"
                              value={femaleRandomCallRate}
                              onChange={(e) => setFemaleRandomCallRate(e.target.value)}
                            />
                            {errors.femaleRandomCallRate && (
                              <div className="ml-2 mt-1">
                                {errors.femaleRandomCallRate && (
                                  <div className="pl-1 text__left">
                                    <span className="text-red">
                                      {errors.femaleRandomCallRate}
                                    </span>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                          <div class="col-md-6">
                            <label for="maleRandomCallRate" class="form-label">
                              Male Random Call Charge per min
                            </label>
                            <input
                              type="number"
                              class="form-control"
                              id="maleRandomCallRate"
                              value={maleRandomCallRate}
                              onChange={(e) => setMaleRandomCallRate(e.target.value)}
                            />
                            {errors.maleRandomCallRate && (
                              <div className="ml-2 mt-1">
                                {errors.maleRandomCallRate && (
                                  <div className="pl-1 text__left">
                                    <span className="text-red">
                                      {errors.maleRandomCallRate}
                                    </span>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                        <div class="mb-3 row">
                          <div class="col-md-6">
                            <label for="bothRandomCallRate" class="form-label">
                              Both Random Call Charge per min
                            </label>
                            <input
                              type="number"
                              class="form-control"
                              id="maleCallCharge"
                              value={bothRandomCallRate}
                              onChange={(e) => setBothRandomCallRate(e.target.value)}
                            />
                            {errors.bothRandomCallRate && (
                              <div className="ml-2 mt-1">
                                {errors.bothRandomCallRate && (
                                  <div className="pl-1 text__left">
                                    <span className="text-red">
                                      {errors.bothRandomCallRate}
                                    </span>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </form>
                    </div>
                    <div className="col-md-6 col-6">
                      <h5 class="card-title ">Private Call Charges</h5>
                      <form>
                        <div class="mb-3 row">
                          <div class="col-md-6">
                            <label for="femaleCallCHarge" class="form-label">
                              Female Call Charge (per min for user)
                            </label>
                            <input
                              type="number"
                              class="form-control"
                              id="femaleCallCharge"
                              value={femaleCallCharge}
                              onChange={(e) => setFemaleCallCharge(e.target.value)}
                            />
                            {errors.femaleCallCharge && (
                              <div className="ml-2 mt-1">
                                {errors.femaleCallCharge && (
                                  <div className="pl-1 text__left">
                                    <span className="text-red">
                                      {errors.femaleCallCharge}
                                    </span>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                          <div class="col-md-6">
                            <label for="maleCoinCharge" class="form-label">
                              Male Call Charge (per min for user)
                            </label>
                            <input
                              type="number"
                              class="form-control"
                              id="maleCallCharge"
                              value={maleCallCharge}
                              onChange={(e) => setMaleCallCharge(e.target.value)}
                            />
                            {errors.maleCallCharge && (
                              <div className="ml-2 mt-1">
                                {errors.maleCallCharge && (
                                  <div className="pl-1 text__left">
                                    <span className="text-red">
                                      {errors.maleCallCharge}
                                    </span>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                        <div class="mb-3 row">
                          <div className="col-md-6">
                            <label for="callCharge" class="form-label">
                              Currency
                            </label>
                            <select
                              class="form-select form-control"
                              aria-label="Default select example"
                              value={currency}
                              onChange={(e) => {
                                setCurrency(e.target.value);
                              }}
                            >
                              <option value="$" selected>
                                $
                              </option>
                              <option value="₹">₹</option>
                              {/* <option value="£">£</option> */}
                            </select>
                          </div>
                          <div class="col-md-6">
                            <label for="callReceiverPercent" class="form-label">
                              call Receiver Percent
                            </label>
                            <input
                              type="number"
                              class="form-control"
                              id="maleCallCharge"
                              value={callReceiverPercent}
                              onChange={(e) => setCallReceiverPercent(e.target.value)}
                            />
                            {errors.callReceiverPercent && (
                              <div className="ml-2 mt-1">
                                {errors.callReceiverPercent && (
                                  <div className="pl-1 text__left">
                                    <span className="text-red">
                                      {errors.callReceiverPercent}
                                    </span>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </form>
                    </div>
                    <div class="row">
                      <div className="d-flex justify-content-end">
                        <button
                          type="button"
                          class="btn btn-danger "
                          onClick={handleSubmit}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class=" col-12">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <h5 class="card-title ">Coin Setup</h5>
                    <form>
                      <div class="mb-3 row">
                        <div className="col-5">
                          <label for="rCoin" class="form-label">
                            RCoin Rate (for cash out conversion ratio)
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            id="rCoin"
                            value={
                              currency === "$"
                                ? "1 Dollar"
                                : currency === "₹" && "1 Rupee"
                              // : "1 GBP"
                            }
                            disabled
                          />
                        </div>
                        <div className="col-1 mt-5">=</div>
                        <div className="col-6">
                          <label for="rCoin" class="form-label">
                            How Many RCoin ( Diamond to $ conversion ratio )
                          </label>
                          <input
                            type="number"
                            class="form-control"
                            id="rCoin"
                            value={rCoinForCaseOut}
                            onChange={(e) => setRCoinForCaseOut(e.target.value)}
                          />
                          {errors.rCoinForCaseOut && (
                            <div className="ml-2 mt-1">
                              {errors.rCoinForCaseOut && (
                                <div className="pl-1 text__left">
                                  <span className="text-red">
                                    {errors.rCoinForCaseOut}
                                  </span>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                      <div class="mb-3 row">
                        <div className="col-5">
                          <label for="rCoin" class="form-label">
                            Diamond
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            id="rCoin"
                            value="1 Diamond"
                            disabled
                          />
                        </div>
                        <div className="col-1 mt-5">=</div>
                        <div className="col-6">
                          <label for="rCoin" class="form-label">
                            How Many RCoin ( Diamond to Rcoin conversion ratio )
                          </label>
                          <input
                            type="number"
                            class="form-control"
                            id="rCoin"
                            value={rCoinForDiamond}
                            onChange={(e) => setRCoinForDiamond(e.target.value)}
                          />
                          {errors.rCoinForDiamond && (
                            <div className="ml-2 mt-1">
                              {errors.rCoinForDiamond && (
                                <div className="pl-1 text__left">
                                  <span className="text-red">
                                    {errors.rCoinForDiamond}
                                  </span>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </form>
                    <div className="d-flex justify-content-end">
                      <button
                        type="button"
                        class="btn btn-danger "
                        onClick={handleSubmit}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </>
      )}

      {type === 'redeemSetting' && (
        <div className="col-12">
          <div class="row">
            <div class="col-md-12 col-12">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <form>
                      <div class="mb-3">
                        <label for="googlePlayEmail" class="form-label">
                          Payment Gateway (option for cash out for user)
                        </label>
                        <Multiselect
                          options={option} // Options to display in the dropdown
                          selectedValues={selectedValue} // Preselected value to persist in dropdown
                          onSelect={onSelect} // Function will trigger on select event
                          onRemove={onRemove} // Function will trigger on remove event
                          displayValue="name" // Property name to display in the dropdown options
                        />
                      </div>

                      <div className="row">
                        <div class=" col-12 mb-2">
                          <label for="minRCoinForCaseOut" class="form-label">
                            Minimum RCoin for cash out (User)
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            id="minRCoinForCaseOut"
                            value={minRCoinForCaseOut}
                            onChange={(e) =>
                              setMinRCoinForCaseOut(e.target.value)
                            }
                          />
                          {errors.minRCoinForCaseOut && (
                            <div className="ml-2 mt-1">
                              {errors.minRCoinForCaseOut && (
                                <div className="pl-1 text__left">
                                  <span className="text-red">
                                    {errors.minRCoinForCaseOut}
                                  </span>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="d-flex justify-content-end">
                        <button
                          type="button"
                          class="btn btn-danger "
                          onClick={handleSubmit}
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {type === 'agoraSetting' && (
        <div className="row">
          <div className="col-12">
            <div class="row">
              <div class="col-md-12 col-12">
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      <form>
                        <div class="mb-3">
                          <label for="googlePlayEmail" class="form-label">
                            Agora Key
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            id="googlePlayEmail"
                            value={agoraKey}
                            onChange={(e) => setAgoraKey(e.target.value)}
                          />
                        </div>
                        <div class="mb-3">
                          <label for="key" class="form-label">
                            Agora Certificates
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            id="key"
                            value={agoraCertificate}
                            onChange={(e) => setAgoraCertificate(e.target.value)}
                          />
                        </div>
                        <div className="d-flex justify-content-end">
                          <button
                            type="button"
                            class="btn btn-danger "
                            onClick={handleSubmit}
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {type === 'paymentSetting' && (
        <div className="col-12">
          <div class="row">
            <div class=" col-12">
              <div class="card">
                <div class="card-body">
                  <div className="row">
                    <div className="col-md-6 col-12">
                      <h5 class="card-title d-flex justify-content-between align-items-center">
                        Stripe (enable/disable in app)
                        <label class="switch">
                          <input
                            type="checkbox"
                            checked={stripeSwitch}
                            onChange={() => handleSwitch_("stripe")}
                          />
                          <span class="slider">
                            <p
                              style={{
                                fontSize: 12,
                                marginLeft: `${stripeSwitch ? "7px" : "35px"}`,
                                color: `${stripeSwitch ? "#fff" : "#000"}`,
                                marginTop: "6px",
                              }}
                            >
                              {stripeSwitch ? "Yes" : "No"}
                            </p>
                          </span>
                        </label>
                      </h5>
                    </div>
                    <div className="col-md-6 col-12">
                      <h5 class="card-title d-flex justify-content-between align-items-center">
                        Google Play
                        <label class="switch">
                          <input
                            type="checkbox"
                            checked={googlePlaySwitch}
                            onChange={() => handleSwitch_("googlePlay")}
                          />
                          <span class="slider">
                            <p
                              style={{
                                fontSize: 12,
                                marginLeft: `${googlePlaySwitch ? "7px" : "35px"
                                  }`,
                                color: `${googlePlaySwitch ? "#fff" : "#000"}`,
                                marginTop: "6px",
                              }}
                            >
                              {googlePlaySwitch ? "Yes" : "No"}
                            </p>
                          </span>
                        </label>
                      </h5>
                    </div>
                  </div>
                  <form>
                    <div class="mb-3">
                      <label for="publishableKey" class="form-label">
                        Stripe Publishable Key
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="publishableKey"
                        value={stripePublishableKey}
                        onChange={(e) =>
                          setStripePublishableKey(e.target.value)
                        }
                      />
                    </div>
                    <div class="mb-3">
                      <label for="secretKey" class="form-label">
                        Stripe Secret Key
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="secretKey"
                        value={stripeSecretKey}
                        onChange={(e) => setStripeSecretKey(e.target.value)}
                      />
                    </div>
                  </form>
                  <div className="d-flex justify-content-end">
                    <button
                      type="button"
                      class="btn btn-danger "
                      onClick={handleSubmit}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {type === 'gameSetting' && (
        <div className="col-12">
          <div className="card">
            <div className="card-body"
              style={{
                height: "370px"
              }}
            >
              <div className="row">
                <form>
                  <div className="row d-flex justify-content-between">
                    <p>Game Diamonds Setting (coin options in game)</p>
                    <div className="col-md-2">
                      {" "}
                      <div className="mb-3">
                        <label for="callCharge" className="form-label">
                          Diamond 1
                        </label>
                        <input
                          min="0"
                          type="number"
                          className="form-control"
                          id="callCharge"
                          value={gameCoin1}
                          onChange={(e) =>
                            setGameCoin1(parseInt(e.target.value))
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-2">
                      {" "}
                      <div className="mb-3">
                        <label for="callCharge" className="form-label">
                          Diamond 2
                        </label>
                        <input
                          type="number"
                          min="0"
                          className="form-control"
                          id="callCharge"
                          value={gameCoin2}
                          onChange={(e) =>
                            setGameCoin2(parseInt(e.target.value))
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-2">
                      {" "}
                      <div className="mb-3">
                        <label for="callCharge" className="form-label">
                          Diamond 3
                        </label>
                        <input
                          min="0"
                          type="number"
                          className="form-control"
                          id="callCharge"
                          value={gameCoin3}
                          onChange={(e) =>
                            setGameCoin3(parseInt(e.target.value))
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="mb-3">
                        <label for="callCharge" className="form-label">
                          Diamond 4
                        </label>
                        <input
                          min="0"
                          type="number"
                          className="form-control"
                          id="callCharge"
                          value={gameCoin4}
                          onChange={(e) =>
                            setGameCoin4(parseInt(e.target.value))
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-2">
                      {" "}
                      <div className="mb-3">
                        <label for="callCharge" className="form-label">
                          Diamond 5
                        </label>
                        <input
                          min="0"
                          type="number"
                          className="form-control"
                          id="callCharge"
                          value={gameCoin5}
                          onChange={(e) =>
                            setGameCoin5(parseInt(e.target.value))
                          }
                        />
                      </div>
                    </div>

                    {errors.gameCoin1 && (
                      <div className="ml-2 mt-1">
                        {errors.gameCoin1 && (
                          <div className="pl-1 text__left">
                            <span className="text-red">{errors.gameCoin1}</span>
                          </div>
                        )}
                      </div>
                    )}

                    {errors.gameCoin2 && (
                      <div className="ml-2 mt-1">
                        {errors.gameCoin2 && (
                          <div className="pl-1 text__left">
                            <span className="text-red">{errors.gameCoin2}</span>
                          </div>
                        )}
                      </div>
                    )}
                    {errors.gameCoin3 && (
                      <div className="ml-2 mt-1">
                        {errors.gameCoin3 && (
                          <div className="pl-1 text__left">
                            <span className="text-red">{errors.gameCoin3}</span>
                          </div>
                        )}
                      </div>
                    )}
                    {errors.gameCoin4 && (
                      <div className="ml-2 mt-1">
                        {errors.gameCoin4 && (
                          <div className="pl-1 text__left">
                            <span className="text-red">{errors.gameCoin4}</span>
                          </div>
                        )}
                      </div>
                    )}
                    {errors.gameCoin5 && (
                      <div className="ml-2 mt-1">
                        {errors.gameCoin5 && (
                          <div className="pl-1 text__left">
                            <span className="text-red">{errors.gameCoin5}</span>
                          </div>
                        )}
                      </div>
                    )}
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label for="callCharge" className="form-label">
                          Game Announcement Coin
                        </label>
                        <input
                          min="0"
                          type="number"
                          className="form-control"
                          id="callCharge"
                          value={coinForGameAnnouncement}
                          onChange={(e) =>
                            setCoinForGameAnnouncement(parseInt(e.target.value))
                          }
                        />
                      </div>
                      {errors.coinForGameAnnouncement && (
                        <div className="ml-2 mt-1">
                          {errors.coinForGameAnnouncement && (
                            <div className="pl-1 text__left">
                              <span className="text-red">
                                {errors.coinForGameAnnouncement}
                              </span>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="d-flex justify-content-end">
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={handleSubmit}
                      style={{
                        marginTop: "70px"
                      }}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}

      {type === 'firebaseSetting' && (
        <div className="col-12">
          <div class="row">
            <div class=" col-12">
              <div class="card">
                <div class="card-body">
                  <form>
                    <div className="">
                      <label class="form-label" htmlFor="privateKey">
                        Private Key JSON ( use for firebase push notification)
                      </label>
                      <textarea
                        name=""
                        className="form-control mt-2"
                        id="privateKey"
                        rows={10}
                        value={privateKey}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          try {
                            const newData = JSON.parse(newValue);
                            setPrivateKey(newValue);
                            setError("");
                          } catch (error) {
                            // Handle invalid JSON input
                            console.error("Invalid JSON input:", error);
                            setPrivateKey(newValue);
                            return setError({
                              ...error,
                              privateKey: "Invalid JSON input",
                            });
                          }
                        }}
                      ></textarea>

                      {errors.privateKey && (
                        <div className="ml-2 mt-1">
                          {errors.privateKey && (
                            <div className="pl-1 text__left">
                              <span className="text-red">
                                {errors.privateKey}
                              </span>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </form>
                  <div className="d-flex justify-content-end mt-3">
                    <button
                      type="button"
                      class="btn btn-danger "
                      onClick={handleSubmit}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {type === 'otherSetting' && (
        <>
          <div class="col-12">
            <h3 className="mb-3 text-white">Other Setting</h3>
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <form>
                    <div className="row">
                      <div class="mb-3 col-md-6">
                        <label for="key" class="form-label">
                          PK-End Time (max time in seconds)
                        </label>
                        <input
                          type="number"
                          class="form-control"
                          id="key"
                          value={pkEndTime}
                          onChange={(e) => setPkEndTime(e.target.value)}
                        />
                        {errors.pkEndTime && (
                          <div className="ml-2 mt-1">
                            {errors.pkEndTime && (
                              <div className="pl-1 text__left">
                                <span className="text-red">
                                  {errors.pkEndTime}
                                </span>
                              </div>
                            )}
                          </div>
                        )}
                      </div>

                      <div class="mb-3 col-md-6">
                        <label for="key" class="form-label">
                          Room Announcement Coin
                        </label>
                        <input
                          type="number"
                          class="form-control"
                          id="key"
                          value={coinForAllRoomAnnouncement}
                          onChange={(e) =>
                            setCoinForAllRoomAnnouncement(e.target.value)
                          }
                        />
                        {errors.coinForAllRoomAnnouncement && (
                          <div className="ml-2 mt-1">
                            {errors.coinForAllRoomAnnouncement && (
                              <div className="pl-1 text__left">
                                <span className="text-red">
                                  {errors.coinForAllRoomAnnouncement}
                                </span>
                              </div>
                            )}
                          </div>
                        )}
                      </div>

                      <div class="mb-3">
                        <label for="key" class="form-label">
                          PK Punishement-End Time (max time in seconds)
                        </label>
                        <input
                          type="number"
                          class="form-control"
                          id="key"
                          value={pkPunishmentEndTime}
                          onChange={(e) => setPkPunishmentEndTime(e.target.value)}
                        />
                        {errors.pkPunishmentEndTime && (
                          <div className="ml-2 mt-1">
                            {errors.pkPunishmentEndTime && (
                              <div className="pl-1 text__left">
                                <span className="text-red">
                                  {errors.pkPunishmentEndTime}
                                </span>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div class="mb-5 col-md-6">
                        <label for="vipDiamond" class="form-label">
                          Vip Diamond Bonus
                        </label>
                        <input
                          type="number"
                          class="form-control"
                          id="vipDiamond"
                          min="0"
                          value={vipDiamond}
                          onChange={(e) =>
                            setVipDiamond(parseInt(e.target.value))
                          }
                        />
                        {errors.vipDiamond && (
                          <div className="ml-2 mt-1">
                            {errors.vipDiamond && (
                              <div className="pl-1 text__left">
                                <span className="text-red">
                                  {errors.vipDiamond}
                                </span>
                              </div>
                            )}
                          </div>
                        )}
                      </div>

                      <div class="col-md-6">
                        <label for="vipDiamond" class="form-label">
                          Bd Commission (%)
                        </label>
                        <input
                          type="number"
                          class="form-control"
                          id="vipDiamond"
                          min="0"
                          value={bdCommsissionPercent}
                          onChange={(e) =>
                            setBdCommsissionPercent(e.target.value)
                          }
                        />
                        {errors.bdCommsissionPercent && (
                          <div className="ml-2 mt-1">
                            {errors.bdCommsissionPercent && (
                              <div className="pl-1 text__left">
                                <span className="text-red">
                                  {errors.bdCommsissionPercent}
                                </span>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>

                    <div
                      className="d-flex justify-content-end "
                    // style={{ marginTop: "5px" }}
                    >
                      <button
                        type="button"
                        class="btn btn-danger "
                        onClick={handleSubmit}
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <h3 className="mb-3 text-white">Monthly Report Download Setting</h3>
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="d-flex align-items-center">
                    <button className="btn btn-primary" onClick={openPicker}>
                      Select Month: {selectedMonth.month}/{selectedMonth.year}
                    </button>
                  </div>
                  <MonthPicker
                    ref={pickerRef}
                    years={{ min: 2000, max: currentDate.getFullYear() }} // Restrict future years
                    value={selectedMonth}
                    onChange={handleMonthChange}
                    lang={{ months: "Jan Feb Mar Apr May Jun Jul Aug Sep Oct Nov Dec".split(" ") }}
                  />
                  <div className="d-flex justify-content-end">
                    <button
                      type="button"
                      className="btn btn-danger"
                      style={{ marginTop: "70px" }}
                      onClick={handleDownload}
                      disabled={isFutureOrCurrentMonth()} // Disable button for current & future months
                    >
                      Download
                    </button>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default connect(null, { getSetting, updateSetting, handleSwitch })(
  Setting
);
